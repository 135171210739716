body {
    overflow-x: hidden; /* This will prevent horizontal scrolling */
}

.recruiter_home {
    width:100vw;
    display: flex;
    flex-direction: column;
}

.hero_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #000000; /* Background color set to black */
    color: #FFFFFF; /* Text color set to white */
    box-sizing: border-box; /* Ensures padding is included in the width */
    background-image: radial-gradient(circle at center, rgba(255,255,255,0.1), rgba(0,0,0,0));
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.ai-label {
    display: inline-flex;
    align-items: center;
    background-color: #333333;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 16px;
    position: relative;
    top: 40px;
}

.new-tag {
    font-weight: bold;
    background-color: #444444;
    padding: 2px 6px;
    border-radius: 12px;
    margin-right: 8px;
}

.ai-text {
    color: #cdcdcd; /* Gray color */
    font-weight: 500; /* Medium font weight */
}

.hero_title {
    font-size: 60px; /* You may need to adjust this depending on the viewport */
    padding-left: 20%; /* Adjust this value based on your design requirements */
    padding-right: 20%; /* Adjust this value based on your design requirements */
    margin-top: 5%;
    font-weight: bold;
    font-weight: 600;
    margin-bottom: 20px; /* Space between title and subtitle */
    text-align: center;
    width: 100%; /* Ensures text alignment is consistent with padding */
}

.hero_subtitle {
    font-size: 25px;
    margin-bottom: 40px; /* Space between subtitle and button */
    text-align: center;
    color: #cdcdcd;
    font-weight: 400;
    width: 100%; /* Ensures text alignment is consistent with padding */
}

.demo_request_button {
    background-color: #22C55E; /* Nice green color */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the text */
    font-family: 'Montserrat';
    text-decoration: none;
    font-size: 16px; /* Suitable text size */
    font-weight: bold; /* Bold text */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates clickable */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-top: 10px;
}

.comparison-container {
    color: white;
    margin-top: 5%;
  }
  
  .blocks {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-top: 30px;
  }
  
  .block {
    flex: 1;
    border-radius: 10px;
    padding: 29px 60px; /* Wider padding */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: white;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .block h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .block .description {
    font-size: 1rem;
    color: #b0b0b0;
  }
  
  .metrics {
    gap: 25px;
    display: flex;
    justify-content: space-between;
  }
  
  .metrics .number {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .status-quo {
    border: 3px solid rgba(255, 255, 255, 0.2);
  }
  
  .with-nora {
    border: 3px solid rgba(0, 255, 0, 0.4);
    background: radial-gradient(circle, rgba(0, 255, 0, 0.2) 0%, transparent 80%);
    position: relative;
  }
  
  .with-nora:before {
    content: ' ';
    position: absolute;
    inset: 0;
    border-radius: 10px;
    background: rgba(0, 255, 0, 0.2);
    filter: blur(15px);
    z-index: -1;
  }


.demo_request_button:hover {
    background-color: #16A34A; /* Slightly darker green on hover for visual feedback */
    color :#cdcdcd;
}

.job_description_button {
    background-color: #213546; /* Nice green color */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the text */
    font-family: 'Montserrat';
    text-decoration: none;
    font-size: 16px; /* Suitable text size */
    font-weight: bold; /* Bold text */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates clickable */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-bottom: 20px;
}

.job_description_button:hover {
    background-color: #456b8d; /* Slightly darker green on hover for visual feedback */
    color :#cdcdcd;
}
.btn-container {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.email-input-container {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
  }
  
  .email-input {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    /* width: 300px; */
  }
  
  .email-submit-button {
    background-color: #22C55E; /* Green background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .email-submit-button:hover {
    background-color: #16A34A; /* Darker green on hover */
  }  

.logo-slider {
    width: 100%;
    overflow-x: hidden;
    margin-top: 7%;
}

.trusted-text {
    color: #FFFFFF;  /* Assuming white text color */
    font-size: 14px;  /* Smaller font size for subtlety */
    text-align: center;  /* Center-align the text */
    font-weight: bold;
    padding: 20px 0;  /* Add some padding above and below for spacing */
    text-transform: uppercase;  /* Uppercase text for emphasis */
    letter-spacing: 1px;  /* Increase letter spacing for readability */
    font-family: 'Montserrat';
}

.logo-track {
    display: flex;
    animation: scroll-logos 20s linear infinite;
}

.logo-item {
    width: 200px; /* Adjust based on your logo size */
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center; /* Center logo horizontally */
    align-items: center; /* Center logo vertically */
}

.logo-item img {
    max-height: 40px; /* Limit logo height */
    max-width: 80px;
    width: auto; /* Maintain aspect ratio */
}

@keyframes scroll-logos {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); /* Assuming logos fill up at least 200% width */
    }
}

.hiring-stats-container {
    color: #FFFFFF; /* White text */
    text-align: center; /* Center the content */
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    align-items: center;
    justify-content: center;
}

.stats-content {
    display: flex;
    align-items: center; /* Align the text and image vertically */
    justify-content: center; /* Center the content horizontally */
    gap: 20px; /* Add space between text and Google review */
    margin-left: 20%;
    margin-right: 20%;
}

.stats-heading {
    font-size: 24px; /* Adjust based on your design */
    font-weight: bold;
}

.fields-container {
    display: flex;
    justify-content: center; /* Center the fields horizontally */
    gap: 10px; /* Space between fields */
    margin-top: 20px; /* Space above the fields */
    flex-wrap: wrap;
}

.field {
    background-color: #333333; /* Dark grey background */
    color: #FFFFFF; /* White text for contrast */
    padding: 2px 10px; /* Slightly larger padding for a better visual */
    border-radius: 20px; /* More pronounced rounded corners */
    font-size: 14px; /* Slightly smaller font size */
    font-weight: 500; /* Medium font weight */
    margin: 0 5px; /* Margin to space out the fields */
    box-shadow: 0 4px 8px rgba(0,0,0,0.15); /* Subtle shadow for depth */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for interactions */
}

.field:hover {
    background-color: #555555; /* Slightly lighter grey on hover */
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); /* Increased shadow on hover for a lifting effect */
    cursor: pointer; /* Cursor change to indicate interactivity */
}

.google-review {
    display: flex;
    align-items: center; /* Align the image and text vertically */
    justify-content: center; /* Center this section */
}

.google-review img {
    width: 100px; /* Adjust based on your image size */
    height: auto;
    margin-right: 5px; /* Space between the image and text */
}

.network-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10%;
    margin-left: 10%;
    color: #FFFFFF;
}

.network-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.network-text {
    margin-right: 5%; /* Space between text and logos */
    flex-basis: 50%;
}

.network-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
}

.network-description {
    font-size: 18px;
    margin-bottom: 20px;
}

.demo_request_button {
    margin-bottom: 20px;
}

.university-partnership {
    flex-basis: 50%;
    max-width: 50vw;
    text-align: center;
}

.trusted-text {
    font-size: 12px;
    font-weight: bold;
}

.university-logos {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.university-slide {
    display: inline-block;
    overflow: hidden;
    animation: scroll-logos 20s infinite linear;
}
@keyframes scroll-logos {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%); /* Adjust based on the total width of the logos */
    }
}

.university-logo-item {
    transition: transform 0.3s ease;
    margin: 0 30px;
    display: inline;
}
.university-logo-item img {
    height: auto; /* Maintain a uniform size for all logos */
    width: 50px;
}

.university-logo-item img:hover {
    transform: scale(1.1);
}

.outsourcing-solution {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    color: #fff; /* White text for visibility */
    display: flex;
    flex-direction: column;
}

.outsourcing-heading {
    font-size: 32px; /* Large font size for section heading */
    margin-bottom: 10px; /* Space between heading and description */
    font-weight: bold;
    text-align: center;
}

.outsourcing-description {
    font-size: 20px; /* Smaller font size for descriptive text */
    margin-bottom: 30px; /* Extra space below the text before next section */
}

.how-it-works-1-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-left: 10%;
    color: #FFFFFF;
}

.how-it-works-1-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.image-interview-styling {
    max-width: 60%;
    margin-top: 5%;
    height: auto;
    display: block; 
    margin-left: auto; 
    margin-right: auto;
}

.image1-styling {
    max-width: 52%;
    height: auto;
    border-radius: 20px; 
    overflow: hidden; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
}

.image2-styling {
    max-width: 110%;
    height: auto;
}

.image3-styling {
    max-width: 55%;
    height: auto;
    margin-left: -10%;
}

.how-it-works-2-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.how-it-works-1-text {
    margin-right: 5%; 
    width: 70%;
}

.how-it-works-1-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.how-it-works-1-description {
    margin-top: 20px;
    font-size: 16px;
}

.how-it-works-1-description li {
    margin-top: 15px; 
}

.how-it-works-1-partnership {
    width: 100%;
    text-align: center;
}

.how-it-works-2-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
    margin-left: 6%;
    color: #FFFFFF;
}

.how-it-works-2-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-left: 25%;
    text-align: left;
}

.how-it-works-2-description {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 25%;
    width: 50%;
    text-align: left;
}

.how-it-works-2-sub-description {
    font-size: 9px;
    margin-bottom: 20px;
}

.how-it-works-3-description {
    font-size: 18px;
    margin-bottom: 50px;
}

.how-it-works-3-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
    margin-left: 10%;
    color: #FFFFFF;
}

.cta-container {
    display: flex;
    margin-top: 10%;
    /* width: 50%;  */
    padding: 0 30px;
    margin-bottom: 30px;
}

.swiper-pagination-bullet {
    background-color: #FFFFFF !important; /* Inactive bullet color */
    opacity: 1; /* Ensure full opacity */
}

.swiper-pagination-bullet-active {
    background-color: #22C55E !important; /* Active bullet color */
}

.cta-text {
    color: #FFFFFF; /* White color for the text */
    margin-right: 30px; /* Space between text and button */
    font-size: 32px; /* Large font size for section heading */
    margin-bottom: 10px; /* Space between heading and description */
    font-weight: bold;
    text-align: left; /* Ensures text aligns left */
}

.youtube-slider {
    padding: 20px 0;
    background-color: #000; 
    margin-bottom: 30px;
  }
  
  .youtube-slider iframe {
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }

.carousel {
    width: 90%;
    padding: 0 80px;
}
@media only screen and (max-width: 600px) {
    .btn-container {
        flex-direction: column;
        justify-content: start;
    }

    .blocks {
        display: block; /* Stack the blocks vertically */
    }

    .block {
        margin-bottom: 40px; /* Add spacing between stacked blocks */
    }

    .comparison-container {
        width: 90%;
        margin-top: 15%;
    }

    .logo-track {
        display: flex;
        animation: scroll-logos 10s linear infinite;
    }

    .image1-styling {
        max-width: 100%;
        height: auto;
    }
    
    .image2-styling {
        margin-top: 10%;
        max-width: 110%;
        height: auto;
    }
    
    .image3-styling {
        max-width: 95%;
        height: auto;
        margin-left: 0%;
    }

    .cta-container {
        margin-top: 12%;
        width: 100%; 
        padding: 0 30px;
        margin-bottom: 30px;
        flex-direction: column;
    }
    .how-it-works-3-description {
        width: 100%;
    }
    
    .how-it-works-3-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 7%;
        margin-left: 0;
        color: #FFFFFF;
        padding: 0 1.5rem;
    }
    .carousel {
        width: 100%;
        padding: 0 10px;
    }
}

.card {
    background: #FFFFFF;
    border-radius: 20px;
    width: 330px;
    padding: 30px;
    height: 360px;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    cursor: pointer;
    z-index: 10;
}

.carousel-button.left {
    left: 10px;
}

.carousel-button.right {
    right: 10px;
}

.founder-image {
    width: 60px;
    height: 60px;
}

.company-logo {
    position: absolute; /* Use absolute positioning within the card */
    top: 3%; /* Adjust this value to push the logo lower */
    right: 15px; /* Adjust this value to move the logo more to the right */
    width: 60px; /* Adjust according to your logo size */
    height: 60px; /* Maintain aspect ratio or adjust as needed */
}

.card-content h3 {
    margin-top: 10px;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
}

.card-content .title {
    color: #555;
    font-size: 14px;
    margin-bottom: 10px;
}

.card-content .quote {
    font-size: 15px;
    color: #000000;
    margin-top: 10px;
    font-weight: 600;
}

.custom-solutions-container {
    color: #fff; /* White text */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spread out items */
    padding: 20px 50px; /* Add some padding around */
    overflow: hidden; /* Hide overflow */
    margin-left: 10%;
    margin-top: 10%;
}

.custom-solutions-left {
    font-size: 40px; /* Bigger font size for emphasis */
    font-weight: bold;
    max-width: 25%; /* Limit width to wrap the text */
    line-height: 1.2; /* Adjust line height for better readability */
}

.custom-solutions-right {
    font-size: 21px;
    max-width: 50%; /* Allocate more width to this part */
    line-height: 1.5; /* Slightly more spaced lines */
    text-align: left; /* Right align the text */
    margin-right: 15%;
}
@media only screen and (max-width: 600px) {
    .custom-solutions-container {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        padding: 20px 1.5rem;
    }
    .custom-solutions-left {
        width: 100%;
        max-width: 100%;
    }
    
    .custom-solutions-right {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
    }
}

.pricing-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff; /* White text for contrast */
    margin-top: 7%;
    margin-left: 2%;
}

.features {
    flex: 1;
    margin-right: 20px;
    margin-top: 5%;
    color: #fff; /* Ensures the text is white */
    font-size: 18px; /* Matches the desired text size */
    line-height: 1.2; /* Spacing between lines */
    font-weight: normal; /* Normal font weight for regular text */
}

.features h2 {
    margin-bottom: 45px; /* Space between the header and the first feature */
    font-size: 30px; /* Matches the desired text size */
    font-weight: bold; /* Bold for the header */
}

.features p {
    margin-bottom: 40px;
}

.plans {
    display: flex;
    flex: 2; /* Allocates 2 parts of the space to the plans */
    align-items: center; /* Aligns the images vertically */
    justify-content: space-around; /* Adjust spacing as needed */
}

/* Styles for clickable cards */
.clickable-card {
    cursor: pointer;
    transition: transform 0.3s;
}

.clickable-card:hover {
    transform: scale(1.05);
}

.plans img {
    width: 100%; /* Ensure the image takes the full width of the parent div */
    height: auto; /* Maintain aspect ratio */
    margin-right: 0; /* Ensure no extra margin on the right */
}

/* FAQ Section */
.faq-section {
    margin-top: 7%;
    margin-right: 8%; /* Add this line to move it towards the left */
}
  
.faq-item {
    background: #1c1c1c;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden; /* Ensure content is hidden initially */
    max-height: 60px; /* Adjust to the desired closed height */
    transition: max-height 0.3s ease; /* Smooth transition for opening/closing */
  }
  
.faq-item.open {
    background: #2c2c2c;
    max-height: none; /* Allow for dynamic height */
}
  
.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
}
  
.faq-answer {
    margin-top: 10px;
    font-size: 16px;
}
  
.faq-toggle {
    font-size: 18px;
}  

.component_one_container {
    width:100vw;
    min-height:80vh;
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.component_one_container > .c1_img_container {
    position:absolute;
    top:0;
    right:4vw;
    width: 40vw;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.component_one_container > .c1_img_container > .laptop_img{
    width:80%;
}
.component_one_container .left_side{
    width:50vw;
}
.component_one_container > h1{
    font-size:40px;
    font-weight: 600;
    margin-bottom: 2.5vw;
    word-spacing: 0.5rem;
}
.component_one_container > p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom:2.5vw;
}
.component_one_container a, .component_one_container button{
    text-decoration: none;
    display: inline-block;
}

.component_one_container button, .component_three_container a .plan_button{
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 200px;
    height: 45px;
    margin: 16px 0;
    justify-content: center;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s all;
}

.component_one_container a .outline-button {
    display: flex;
    border: 3px solid #213546;
    border-radius: 5px;
    width: 200px;
    height: 45px;
    margin: 16px 0;
    justify-content: center;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s all;
    background-color: transparent;
}

.component_one_container a .outline-button p{
    margin: auto;
    color: #213546;
    font-size: 16px;
    font-weight: 500;
}

.sign_up_button_container{
    width:100%;
    display:flex;
    align-items: center;
    gap: 20px;
}

.component_one_container button:active, .component_three_container a .plan_button:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
.component_one_container button p{
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.component_three_container a .plan_button p{
    margin: auto;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
}
.component_three_container a .plan_button{
    width:200px;
    height:60px;
}
.component_three_container a .plan_button p{
    font-size: 17px;
}
.component_three_container a{
    text-decoration: none;
}


@media only screen and (max-width: 900px) {
    .plans {
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }
    
    .plans img {
        width: 100%; 
        margin-right: 0px;
    }

    .pricing-container {
        flex-direction: column;
        margin-left: 0;
        padding: 0 1.5rem;
        gap: 16px;
    }
    .features h2 {
        margin-bottom: 10px;
    }
    .features p {
        margin-bottom: 0;
    }
    .component_one_container h1{
        margin-top:5vh;
        font-size: 35px;
    }
    .component_one_container p{
        margin-top: 2.5vh;
        font-size: 20px;
    }
    .component_one_container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .component_one_container > .c1_img_container{
        position: relative;
        display:flex;
        justify-content: center;
        align-items: center;
        right:0;
        width:80vw;
    }
    .component_one_container .left_side{
        width:100%;
        text-align: center;
    }
    .component_one_container .sign_up_button_container{
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0px;
    }
    .component_one_container button{

        width: 171px;
        height: 48px;
        margin: 16px 0;
    }
    .component_one_container button p{
        font-size:16px;
    }
    .component_one_container a .outline-button{
        margin: 0;
        width: 171px;
        height: 48px;
    }
    .component_one_container a .outline-button p{
        font-size:16px;
    }
}

@media only screen and (max-width: 600px) {
    .component_one_container h1 {
        font-size: 25px;
    }

    .component_one_container p {
        font-size: 20px;
    }
    .component_one_container{
        min-height: auto;
        padding-top:2.5vh;
        padding-bottom:2.5vh;
    }
    .component_one_container button {
        width: 131px;
        height: 43px;
        margin: 16px 0;
    }
    .component_one_container button p {
        font-size: 12px;
    }
    .component_one_container a .outline-button {
        width: 131px;
        height: 43px;
    }
    .component_one_container a .outline-button p {
        font-size: 12px;
    }
    .hamburger_nav > .hamburger_left > .navbar_logo > img{
        width:calc(20vw + 10px);
    }
}


.component_two_container{
    min-height: 50vh;
    width:100vw;
    padding: 10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#7FB89880;
    /*background-color: #213546;*/
}

.component_two_container .text_container{
    width:80vw;
    color:white;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.component_two_container .text_container h1{
    font-size: 35px;
    font-weight: 600;
    word-spacing: 0.25em;
}
.component_two_container .text_container p{
    font-size: 25px;
}
.component_two_container .image_container{
    width:90vw;
    display: flex;
}

.component_two_container .image_grid_container{
    width:90vw;
    display:grid;
    grid-template-rows:1fr;
    grid-template-columns:repeat(5,1fr);
    grid-gap:10px;
}
.component_two_container .image_grid_container .grid-item{
    background-color: transparent;
    padding: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
    
}
.component_two_container .image_grid_container .grid-item img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

@media only screen and (max-width:900px){
    .component_two_container .text_container h1{
        font-size: 30px;
        font-weight: 600;
        word-spacing: 0.25em;
    }
    .component_two_container .image_grid_container {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas: 
        'img1 img1 img2 img2 img3 img3'
        '. img4 img4 img5 img5 .';
        grid-gap:1em;
        row-gap: 0.25em;
    }
    .image_grid_container .one{
        grid-area: img1;
    }
    .image_grid_container .two{
        grid-area: img2;
    }
    .image_grid_container .three{
        grid-area: img3;
    }
    .image_grid_container .four{
        grid-area: img4;
    }
    .image_grid_container .five{
        grid-area: img5;
    }
}
@media only screen and (max-width:600px){
    .component_two_container .text_container h1{
        font-size: 25px;
        font-weight: 600;
        word-spacing: 0.25em;
    }
    .component_two_container .text_container p{
        font-size: 15px;
    }
    .faq-section {
        margin-right: 0;
        padding: 0 1.5rem;
    }
}


.component_three_container{
    min-height: 70vh;
    width:100vw;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.component_three_container .header_text, .component_three_container .bottom_text{
    width:80vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:5%;
    text-align: center;
}
.component_three_container .header_text h1{
    font-size:40px;
    font-weight:600;
    /* word-spacing: 10px; */
}
.component_three_container .header_text p, .component_three_container .bottom_text p{
    font-size:20px;
    text-align: center;
    line-height: 30.5px;
    font-weight: 400;
    /* word-spacing:10px; */
    margin-top:10px;
}
.component_three_container .bottom_text{
    margin-bottom:70px;
}
.component_three_container .card_container{
    display:flex;
    justify-content: space-evenly;
    width:100vw;
    align-items: center;

    margin-top:30px;
}
.component_three_container .card_container .left_card, .component_three_container .card_container .right_card{
    height:530px;
    width: 430px;
    background-color:#8EC7A666;
    color:#231D4F;
    display:flex;
    flex-direction: column;
    border-radius: 30px;
}
.component_three_container .card_container .plan {
    font-size:25px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 40px;
    margin-top: 50px;
}
.component_three_container .card_container .price {
    font-size:35px;
    font-weight: 700;
    margin-left: 40px;
    margin-bottom:1px;
}
.component_three_container .list{
    margin-left: 40px;
    font-weight:500;
    font-size:15px;
    color:black;
}
.checklist_container{
    display:flex;
    margin-left: 40px;
    margin-bottom:10px;
    color:black;
}

.checklist_container p{
    margin-bottom: 0;
    margin-left: 10px;
    font-weight:500;
    font-size: 15px;
}
.component_three_container .card_container .card_button_container{
    width:100%;
    display:flex;
    justify-content: center;
}
.component_three_container .card_container .left_card .card_button_container{
    margin-top: 20px;
}
.component_three_container .card_container .right_card .card_button_container{
    margin-top: 20px;
}
.component_three_container .card_container .card_button_container a{
    text-decoration: none;
}
.component_three_container .card_container .card_button_container a .sign_up{
    display: flex;
    background-color: #1DAF5C;
    border-radius: 5px;
    width: 200px;
    height: 48px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s all;
}
.component_three_container .card_container .card_button_container a .sign_up:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
.component_three_container .card_container .card_button_container a .sign_up p{
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.component_three_container .card_container .caption{
    margin-left: 40px;
    margin-top: 20px;
    font-size: 15px;
    font-weight:400;
    margin-right:10px;
}

@media only screen and (max-width:900px){
    .component_three_container .card_container{
        display:flex;
        justify-content: space-evenly;
        width:80vw;
        align-items: center;
        flex-direction: column;
        margin-top:30px;
    }
    .component_three_container .header_text h1{
        font-size: 35px;
    }
    .component_three_container .bottom_text{
        display:none;
    }
    .component_three_container .card_container .left_card, .component_three_container .card_container .right_card{
        margin-bottom:50px;
    }
}

@media only screen and (max-width:600px){
    
    .component_three_container .header_text h1{
        font-size:25px;
    }
    .component_three_container .header_text p{
        font-size:15px;
        min-width:90vw;
    }
    .component_three_container .card_container .left_card, .component_three_container .card_container .right_card{
        height:410px;
        width: 280px;
    }
    .component_three_container .card_container .plan{
        font-size: 15px;
        margin-left: 30px;
        margin-top: 20px;
    }
    .component_three_container .card_container .price {
        font-size: 17px;
        margin-left: 30px;
    }
    .component_three_container .list{
        font-size:11px;
        margin-left:30px;
    }
    .checklist_container{
        margin-left:30px;
    }
    .checklist_container p{
        font-size: 13px;
    }
    .component_three_container .card_container .left_card .card_button_container{
        margin-top: 25px;
    }
    .component_three_container .card_container .right_card .card_button_container{
        margin-top: 10px;
    }
    .component_three_container .card_container .card_button_container a .sign_up{
        width:150px;
        height:40px;
    }
    .component_three_container .card_container .card_button_container a .sign_up p{
        font-size:13px;
    }
    .component_three_container .card_container .caption{
        margin-left:30px;
        font-size: 12px;
    }

}

.component_four_container{
    width:100vw;
    display:flex;
    min-height:50vh;
    flex-direction: column;
    align-items: center;
}
.component_four_container .testimonial_header{
    font-size:40px;
    font-weight:600;
    margin-top: 5vh;
    margin-bottom:5vh;
}
.component_four_container .carousel_wrapper{
    position:relative;
    margin-bottom: 5vh;
}
.component_four_container .carousel_wrapper .carousel{
    width: 84vw;
    position: unset;
}
.component_four_container .item{
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: white;
    margin: 0 16px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    padding:30px;
    height:100%;
}
.component_four_container .react-multi-carousel-list .react-multiple-carousel__arrow--left{
    left: -5vw;
}
.component_four_container .react-multi-carousel-list .react-multiple-carousel__arrow--right{
    right: -5vw;
}
.component_four_container .react-multi-carousel-list .react-multiple-carousel__arrow{
    background: none;
}
.component_four_container .react-multi-carousel-item{
    height:auto;
}
@media only screen and (max-width:900px){
    .component_four_container .testimonial_header{
        font-size: 35px;
    }
}
@media only screen and (max-width:600px){
    .component_four_container .testimonial_header{
        font-size: 25px;
    }
}
.react-multi-carousel-list .react-multi-carousel-dot button {
    background-color: #8EC7A6;
    border: none;
    position: relative;
    bottom: -3.5vh;
}
.react-multi-carousel-list .react-multi-carousel-dot--active button{
    background-color: #1DAF5C;
}

.component_five_container {
    width: 100vw;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    padding-bottom: 3%;
}

.component_five_container .calendly_header {
    margin-top: 6%;
    font-size: 32px;
    font-weight: bold;
    color: white;
    width: 75vw;
    text-align: center;
}

.component_five_container .calendly_subtext {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color:white;
    width: 75vw;
    text-align: center;
}

.component_five_container .calendly_inner_container{
    width: 100vw;
    min-height: 750px;
    position: relative;
}

@media only screen and (max-width: 1010px) {
    .component_five_container .calendly_inner_container {
        width:100vw;
        height:1200px;
        overflow: hidden;
    }
}
@media only screen and (max-width:900px){
    .component_five_container .calendly_header{
        font-size: 30px;
    }
}
@media only screen and (max-width: 850px) {
    .component_five_container .calendly_inner_container {
        width:100vw;
        height:1200px;
        /* min-height:1100px; */
        overflow: hidden;
    }
}
@media only screen and (max-width: 650px){
    .component_five_container .calendly_inner_container{
        height:1100px;
    }
}
@media only screen and (max-width: 600px){
    .component_five_container .calendly_header{
        font-size: 30px;
        margin-top: 5rem;
        width: 85vw;
    }

    .component_five_container .calendly_subtext{
        width: 85vw;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
    
}
@media only screen and (max-width: 500px) {
    .component_five_container .calendly_inner_container {
        width: 90vw;
        height:1100px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 1100px) {
    .how-it-works-2-content {
        flex-direction: column;
    }
    .how-it-works-2-description {
        width: 100%;
        margin-left: 0;
    }
    .how-it-works-2-heading {
        margin-left: 0;
    }
}

@media only screen and (max-width:600px){

    .how-it-works-1-content {
        flex-direction: column;
    }

    .how-it-works-1-section {
        margin-left: 0;
        padding: 0 1.5rem;
    }

    .how-it-works-1-partnership img {
        width: 100%;    
    }

    .how-it-works-1-text {
        margin-right: 0; /* Space between text and logos */
        margin-top: 4rem;
        width: 100%;
    }

    .how-it-works-1-heading {
        font-size: 28px;
        text-align: center;
    }
    
    .how-it-works-1-description {
        width: 100%;
        margin-bottom: 3rem;
    }

    .how-it-works-2-section {
        margin-left: 0;
        padding: 0 1.5rem;
    }

    .how-it-works-2-sub-description {
        margin-bottom: 5rem;
    }

    .how-it-works-2-heading {
        font-size: 30px;
        margin-top: 4rem;
    }

    .network-section {
        margin-left: 0;
        padding: 16px;
        width: 100%;
        display: block;
    }

    .network-content {
        flex-direction: column;
        margin-left: 1rem;
    }

    .network-text {
        margin-right: 0; /* Space between text and logos */
        flex-basis: 50%;
        margin-top: 4rem;
    }

    .university-partnership {
        flex-basis: 100%;
        max-width: 100vw;
    }

    .stats-content {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        margin-top: 4rem;
    }

    .trusted-text {
        margin-top: 2rem;
    }   

    .hero_title {
        font-size: 40px; /* You may need to adjust this depending on the viewport */
        padding: 0 1rem;
        margin-top: 6rem;
        margin-bottom: 3rem;
    }

    .hero_subtitle {
        font-size: 18px;
        margin-bottom: 4rem;
    }

    .outsourcing-solution {
        text-align: left;
        padding: 0 1.5rem;
    }

    .outsourcing-heading {
        font-size: 28px;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    .cta-text {
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    .custom-solutions-left {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    .faq-section {
        margin-top: 7rem;
    }
} 
