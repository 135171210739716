.faq-box {
    border: 1px solid #B5B5B5;
    background: #FFF;
    padding: 23px;
}

.faq-box.top {
    border-top-left-radius: 12px;   
    border-top-right-radius: 12px;
}

.faq-box.bottom {
    border-bottom-left-radius: 12px;   
    border-bottom-right-radius: 12px;
}

.faq-title {
    cursor: pointer;
    color: #035DA9;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.img-arrow{
    width: calc(10px + 0.8vw)
}
.arrow {
    float: right;
    transition: transform 0.3s;
}

.arrow.open {
    transform: rotate(180deg);
}

.faq-content {
    max-height: 0;
    color: #4495d7;
    overflow: hidden;
    transition: max-height 5s linear;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.faq-content.open {
    max-height: 500px; 
}
.faq-content.close{
    transition: max-height 0.75s ease-out;
}

@media only screen and (min-width: 900px){
    .faq-box{
        padding: 25px;
        width:100%;
        cursor: pointer;
    }
    .faq-title {
        color: #035DA9;
        font-size: calc(10px + 0.5vw);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .faq-content{
        font-size: calc(5px + 0.5vw);
    }
}