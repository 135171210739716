* {
    box-sizing: border-box;
}

.company-container {
    background-color: #CDE1D6;
    width: 100%;
    padding: 1em 0;
}

.company-box {
    width: 95%;
    position: relative;
    border: 1px solid white;
    border-radius: 22px;
    margin: 2.5rem auto;
    padding: 75px 0;
    min-height: 90vh;
    background: rgba(250, 250, 250, 0.336);
}

.company-flex-header {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: .8em;
}

.company-header-item {
    align-self: center;
    min-width: 250px;
}

.company-text-header1 {
    color: #06ad0e;
    opacity: .3;
}

.nav-top-2 img {
    width: 12vw;
    margin-left: 18px;
    cursor: pointer;
}

.company-text-header2 {
    text-align: center; /* Center align the text */
    font-size: 33px; /* Adjust size as needed */
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: 600;
    color: black; /* Default color for "About Your" */
}

.company-circle1 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    background: #7ac903;
    border: 2px solid #06ad0e;
    opacity: .3;
}

.company-circle2 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    background: #7ac903;
    border: 2px solid #06ad0e;
}

.company-line {
    position: relative;
    margin-top: -29px;
    margin-left: -108px;
    margin-right: -120px;
    height: 2px;
    width: 10%;
    border: 1px solid #06ad0e;
    opacity: .3;
}

.company-form-inline {
    gap: 1em; 
    width: 100%; 
}

.company-section {
    width: 100%;
}

.company-h1 {
    margin-bottom: -10px;
}

.company-form {
    margin-left: 25%;
    margin-right: 20%;
    margin-top: 2%;
}

.company-form-group {
    padding-top: .8em;
}

.company-form-group label {
    font-weight: 500;
}

.company-label, .company-input1 {
    display: block;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 1px;
}

.company-input1, .company-select1, .company-textarea1 {
    border-radius: 7px;
    border: 1px solid rgb(192, 191, 191);
    font-size: 14px;
}

.company-input1 {
    width: 80%;
    height: 2.8em;
}

.company-select1, .company-textarea1 {
    width: 80%;
    height: 2.8em;
}

.company-textarea1 {
    height: 22vmin;
    resize: none;
}

.company-input2, .company-select2, .company-textarea2 {
    display: none;
}

.company-input1:focus, .company-select1:focus, .company-textarea1:focus {
    outline: none;
}

.company-form-button {
    right: 4em;
    text-align: center;
    height: 60px;
    margin-top: 40px;
    font-size: 14px;
}

.company-button1 {
    border: 1px solid rgb(3, 62, 255);
    color: rgb(0, 4, 250);
    font-weight: 600;
    font-size: 22px;
    background: none;
    border-radius: 7px;
    width: 49%;
    max-width: 220px;
}

.company-button2 {
    background-color: #233242; /* Dark navy blue background */
    color: white; /* White text color */
    font-weight: 600; /* Bold text */
    font-size: 18px; /* Font size */
    border-radius: 10px; /* Rounded corners */
    border: none; /* No border */
    padding: 5px 10px; /* Adjust padding for spacing */
    cursor: pointer; /* Pointer cursor for interaction */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.company-button2:hover {
    background-color: #1b2a37; /* Slightly darker on hover */
}

.company-button1 + .company-button2 {
    margin-left: 40px;
}


@media only screen and (max-width: 525px) {
    .company-container {
        overflow: hidden;
    }

    .company-flex-header {
        font-size: .5em;
    }

    .company-header-item {
        min-width: 150px;
        position: absolute;
    }

    .company-header-item .company-text-header1 {
        margin-right: 160px;
    }

    .company-header-item .company-text-header2 {
        margin-left: 200px;
    }

    .company-circle1 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: -84px;
    }

    .company-circle2 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: 88px;
    }

    .company-line {
        margin-top: -20px;
        margin-left: -108px;
    }

    .company-section {
        width: 80%;
        margin: 1em auto;
    }

    .company-h1 {
        margin-bottom: 2px;
        font-weight: 600;
    }

    .company-form-group {
        padding-top: .5em;
    }

    .company-label, .company-input1, .company-select1, .company-textarea1 {
        display: none;
    }

    .company-input2, .company-select2 {
        display: block;
        width: 100%;
        border: 1px solid rgb(73, 71, 71);
        border-radius: 10px;
        background: none;
        height: 2.5em;
        padding-left: 13px;
    }

    .company-select2 {
        padding-left: 8px;
        color: rgb(114, 113, 113);
    }

    .company-textarea2 {
        display: block;
        width: 100%;
        border: 1px solid rgb(73, 71, 71);
        border-radius: 10px;
        background: none;
        height: 9.5em;
        padding: 5px 0 0 13px;
        font-size: 15px;
    }

    .company-input2:focus, .company-select2:focus, .company-textarea2:focus {
        outline: none;
    }

    .company-form-button {
        justify-content: space-around;
        width: 90%;
    }

    .company-button1 {
        font-size: 12px;
        height: 30px;
    }

    .company-button2 {
        font-size: 12px;
        height: 30px;
    }
}

@media only screen and (max-width: 1350px) and (min-width: 530px) {
    .company-form-button {
        justify-content: space-around;
    }
}

@media only screen and (max-width: 600px) {
    .company-container {
        padding: 1rem;
    }

    .company-form {
        margin-left: 0;
        width: 100%;
    }

    .company-input1, 
    .company-select1, 
    .company-textarea1 {
        width: 100%;
        font-size: 16px;
    }

    .company-form-inline {
        flex-direction: column;
        gap: 1rem;
    }

    .company-form-button {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }

    .company-button2 {
        width: 100%;
        font-size: 18px;
    }

    .nav-top-2 img {
        width: 35%;
        margin: 0 auto;
    }

    .company-text-header2 {
        font-size: 24px;
        text-align: center;
        margin-top: 10%;
    }

    .company-label {
        font-size: 14px;
    }
}