.recruiter-plans{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.plan-container-wrapper{
    padding: 6% 4% 4% 4%;
    flex: 1;
    
}

.plan-background-wrapper{
    background-color: #FFFFFFCC; 
    padding: 12% 4% 4% 4%;
    display: flex;
    flex-direction: column;
    gap: 5em;
}

.plan-description-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}



/* plan A description styling START*/
.plan-description-box{
    border-radius: 20px;
    background: #DBEDE3;
    padding: 4%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2em;
}



.plan-description-price-heading{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.plan-price{
    color: #231D4F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.plan-perhead{
    color: #231D4F;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.plan-description-text{
    color: #000;
    text-align: center;
    font-kerning: none;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.plan-header{
    color: #213546;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.56px;
    margin-bottom: 3%;
}

.plan-cta-button{
    border-radius: 8px;
    background: #035DA9;
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 2%;

}

@media only screen and (min-width: 900px) {
    .plan-background-wrapper{
        padding: 5% 8% 6% 8%;
    }
    .plan-description-box{
        text-align: center;
        padding-left: 16%;
        padding-right: 16%;
        
    }
    .plan-description-wrapper{
        display: flex;
        flex-direction: column;     
        align-items: center;
    }
    .plan-header{
        text-align: center;
        color: #000;
        text-align: center;
        font-size: calc(15px + 1.5vw);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 70%;
    }
    .plan-description-price-heading{
        color: #000;
        font-size: calc(7px + 1.5vw);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .plan-price{
        color: #231D4F;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: 2%;
    }
    .plan-perhead{
        color: #231D4F;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .plan-description-text{
        color: #000;
        text-align: center;
        font-size: calc( 10px + .5vw);
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
    }
    .plan-description-text-note{
        color: #000;
        font-size: calc( 5px + .5vw);
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }
    .plan-cta-button{
        border-radius: 5px;
        padding: 1%;
        width:200px;
        height:50px;
    }
}
@media only screen and (max-width:700px){
    .plan-cta-button{
        border-radius: 5px;
        padding: 1%;
        width:100px;
        height:35px;
        font-size:12px;
    }
    .plan-description-box{
        gap:1em;
    }
}
/* plan A description styling END*/

/* plan Comparison styling START*/
.plan-comparison-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.comparison-heading{
    border-radius: 20px 20px 0px 0px;
    background: #35495A;
    text-align: center;
    padding: 4%;
}

.comparison-heading > h2{
    color: #FFFFFF;
}

.comparison-content-wrapper{
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
    background: #FFF;
}

.comparison-content-wrapper > p{
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}
.plan-comparison-content > li{
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4%;
}

.plan-comparison-box-container{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.plan-A-comparison{
    width:100%

}
.plan-B-comparison{
    width:100%
}

@media only screen and (min-width: 900px){
    .plan-comparison-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .plan-comparison-box-container{
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 1em;
        flex-direction: row;
    }
    .comparison-heading button{
        padding: 2%;
    }
    .comparison-content-wrapper > p{
        color: #000;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .plan-comparison-content >li{
        color: #000;
        font-size: calc(10px + 0.5vw);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .plan-A-comparison{
        width:40%;
        /* height:500px; */
    }
    .plan-B-comparison{
        width:40%;
        /* height:500px; */
    }
    .comparison-content-wrapper{
        height:300px;
    }
}
/* plan Comparison styling END*/

/* FAQ styling START*/
@media only screen and (min-width: 900px){
    .plan-faq-wrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
}
/* FAQ styling END*/


/* Book a call styling START*/
.plan-book-call-wrapper{
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.book-a-call-description{
    color: #6D6C75;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width:70vw;
}

.plan-book-call-wrapper .calendly_container{
    text-align: center;
    overflow: hidden;
    display: flex;
}
.plan-book-call-wrapper .calendly_container .calendly_inner_container{
    width: 100vw;
    min-height: 750px;
    position: relative;
}

@media only screen and (min-width: 900px){
    .plan-book-call-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .book-a-call-description{
        color: #6D6C75;
        text-align: center;
        font-size: calc(5px + 0.5vw);
        font-style: normal;
        font-weight: 500;
        line-height: 30px; 
        width:50vw;
    }
}
.plan-button{
    border-radius: 5px;
    background: #035DA9;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 2%;
    width:130px;
    height:40px;
    margin-top: 10px;
}
/* Book a call styling END*/
