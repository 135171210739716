.edit-position-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .edit-position-container {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    width: 80%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  
  .edit-position-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .edit-position-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .edit-position-form-group {
    margin-bottom: 1.5rem;
  }
  
  .edit-position-form-inline {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .edit-position-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .edit-position-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-position-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 500;
  }