/****** LOGIN ******/

.login {
    display: flex;
    flex-direction: column;
    background-size: cover;
    padding: 2rem 9vw 4rem 2vw;
    height:100vh;
    width:100vw;
    overflow-y: hidden;
    margin-left: 3%;
}

.nav-top img {
    width: 12vw;
    position: absolute;
    top:20px;
    left:20px;
    cursor: pointer;
}

.login-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 7vh;
    width:100%;
    height:100%;
}

.login-container img {
    width: 35vw;
    object-fit: contain;
    height:85%;
}

.login-container .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45vw;
    justify-content: center;
    height: 75vh;

    background-color: #FFFFFF; /* White background for the box */
    border-radius: 10px; /* Rounded corners */
    padding: 2rem; /* Inner spacing */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border: 1px solid #E0E0E0; /* Optional: Light border for a clean edge */
    margin: auto; /* Center horizontally */
}

.login-container .left-container h3 {
    color: #213546;
    font-size: 3.75rem;
    font-weight: 600;
    text-align: center;
}

.login-container .left-container .input-container {
    width: 30vw;
    margin-top: 40px;
    margin-bottom: 20px;
}

.login-container .left-container .input-container .text-container {
    display: flex;
    /* flex-direction: row;
    align-items: center; */
    /* margin-bottom: 10px; */
}

.login-container .left-container .input-container .text-container p {
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
    transform: translateY(0.5rem);
    margin: 7px 10px 0 0;
}

.login-container .left-container .input-container .text-container select {
    height: 2.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    font-family: 'Montserrat';
}

.login-container .left-container .input-container input {
    height: 2.5rem;
    font-size: 14px;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    margin-top: 0.5rem;
    font-family: 'Montserrat';
    margin-bottom: 10px;
}

.login-container .left-container .input-container input::placeholder {
    color: black;
}

.login-container .left-container p b {
    font-weight: 500;
}

.login-container .left-container .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.login-container .left-container .icon-container .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.btn-text{
    display: flex;           
}
.btn-text > p{
    margin-right: 10px;
}

.login-container .left-container .icon-container .icon img {
    width: 2.5rem;
    object-fit: contain;
}

.login-container .left-container .icon-container p {
    font-size: 0.8rem;
}

.login-container .left-container .icon-container p b {
    font-weight: 500;
}

.login-container .left-container .button {
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 9rem;
    height: 3rem;
    margin: 1rem 0;
    cursor: pointer;
}

.login-container .left-container .button p {
    margin: auto;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.login-container .left-container p {
    color: #213546;
    font-weight: 300;
    font-size: 1rem;
    margin-top: 0.25rem;
}

@media only screen and (max-width: 900px) {
    .nav-top img {
        margin-left: 0;
        width: 25vw;
    }
    .login-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height:90%;
    }
    .login-container .left-container {
        width: 84vw;
    }
    .login-container .left-container .input-container {
        width: 40vw;
    }
    .login-container .icon img {
        width: 20vw;
    }
    .login-container .login-img{
        min-width:20vw;
        margin-bottom:3vh;
        height:auto;
        width:30vw;
    }
    .login-container .left-container h3{
        font-size:3.3rem;
    }
    .login{
        padding: 2rem 9vw 2vw;
    }
    

}

@media only screen and (max-width: 710px) {
    .login-container {
        padding-bottom: 2rem;
    }
    .login-container .left-container h3 {
        font-size: 1.75rem;
    }
    .login-container .left-container .input-container {
        width: 55vw;
    }
    .login-container .left-container .input-container input {
        height: 2.25rem;
    }
    .login-container .left-container .input-container .text-container select {
        height: 2.25rem;
    }
    .login-container .left-container .button {
        height: 2.5rem;
    }
    .login-container .left-container{
        height: auto;
    }
}

@media only screen and (min-width: 1300px) {
    .login-container {
        min-height: 92vh;
    }
}


/****** SIGNUP ******/

.signup {
    display: flex;
    flex-direction: column;
    background-size: cover;
    padding: 2rem 9vw 4rem 2vw;
    height:100vh;
    width:100vw;
    overflow-y: hidden;
    margin-left: 3%;
}

.signup-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top:7vh;
    height:100%;
    width:100%;
}

.signup-container .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    justify-content: center;
    height: 80vh;

    background-color: #FFFFFF; /* White background for the box */
    border-radius: 10px; /* Rounded corners */
    padding: 2rem; /* Inner spacing */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border: 1px solid #E0E0E0; /* Optional: Light border for a clean edge */
    margin: auto; /* Center horizontally */
}

.signup-container .left-container h3 {
    color: #213546;
    font-size: 3.75rem;
    font-weight: 600;
    text-align: center;
}

.signup-container .left-container .input-container {
    width: 30vw;
    margin-top: 20px;
}

.signup-container .left-container .input-container .text-container {
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
}

.signup-container .left-container .input-container .text-container p {
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
    transform: translateY(0.5rem);
    margin-right:10px;
    margin-top:7px
}

.signup-container .left-container .input-container .text-container .recruiter-box {
    display: flex;
    height: 2.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
}

.signup-container .left-container .input-container .text-container .recruiter-box p {
    margin: auto 0;
    font-size: 0.85rem;
    font-weight: 400;
    transform: translateY(0);
}

.signup-container .left-container .input-container .text-container select {
    height: 2.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    font-family: 'Montserrat';
}

.signup-container .left-container .input-container input {
    height: 2.5rem;
    font-size: 14px;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    margin-top: 0.5rem;
    font-family: 'Montserrat';
    margin-bottom:10px;
}

.signup-container .left-container .input-container input::placeholder {
    color: black;
}

.signup-container .left-container p b {
    font-weight: 500;
}

.signup-container .left-container .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.signup-container .left-container .icon-container .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.signup-container .left-container .icon-container .icon img {
    width: 2.5rem;
    object-fit: contain;
}

.signup-container .left-container .icon-container p {
    font-size: 0.8rem;
}

.signup-container .left-container .icon-container p b {
    font-weight: 500;
}

.signup-container .left-container .button {
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 9rem;
    height: 3rem;
    margin: 1rem 0;
    cursor: pointer;
}

.signup-container .left-container .button p {
    margin: auto;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.signup-container .left-container p {
    color: #213546;
    font-weight: 300;
    font-size: 1rem;
    margin-top: 0.25rem;
}

.text-input-signup {
    position: relative;
    display: flex;
}  

.text-input-signup .eyeicon {
    position: absolute;
    right: 5%;
    bottom: 1.2rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
.signup-container img {
    width: 35vw;
    object-fit: contain;
    height:85%;
}

.password-restraint-div {
    width: 100%;
    margin: 15px auto 15px auto;
    display: flex;
    flex-direction: row;
    font-size: small;
}

.password-restraint-1 {
    margin-right: 10px;
}

.password-restraint-1 div {
    display: flex;
    flex-direction: row;
}

.password-restraint-2 {
    margin-right: 10px;
}

.password-restraint-2 div {
    display: flex;
    flex-direction: row;
}

.password-restraint-3 div {
    display: flex;
    flex-direction: row;
}

.password-restraint-text {
    margin-left: 5px;
}

.disabled-signup-btn {
    display: flex;
    background-color: lightslategray;
    border-radius: 5px;
    padding: 12px 35px;
    margin: 1rem 0;
}

#disabled-signup-text {
    margin: auto;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.login__forgot-link {
    text-decoration: underline;
    font-weight: 200;
    background: none;
    border: none;
    justify-self: flex-start;
    color: black;
}

@media (max-width: 1580px) {
    .password-restraint-div {
        flex-direction: column;
    }
}

@media only screen and (max-width: 900px) {
    .signup-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: 90%;
    }
    .signup-container .left-container {
        width: 84vw;
    }
    .signup-container .left-container .input-container {
        width: 40vw;
    }
    .signup-container .icon img {
        width: 20vw;
    }
    .signup-container .signup-img{
        min-width: 20vw;
        margin-bottom: 3vh;
        height:auto;
    }
    .signup-container .left-container h3{
        font-size: 3.3rem;
    }
    .signup{
        padding: 2rem 9vw 2vw;
    }
    .signup-container img {
        width: 30vw;
    }
    
}

@media only screen and (max-width: 710px) {
    .signup-container {
        padding-bottom: 2rem;
    }
    .signup-container .left-container h3 {
        font-size: 1.75rem;
    }
    .signup-container .left-container .input-container {
        width: 55vw;
    }
    .signup-container .left-container .input-container input {
        height: 2.25rem;
    }
    .signup-container .left-container .input-container .text-container .recruiter-box {
        height: 2.25rem;
    }
    .signup-container .left-container .button {
        height: 2.5rem;
    }
    .signup-container .left-container{
        height:auto;
    }
}

@media only screen and (min-width: 1300px) {
    .signup-container {
        min-height: 92vh;
    }
}
@media only screen and (max-width:600px){
    .signup-container .signup-img{
        min-width: 200px;
    }
    .login-container .login-img{
        min-width: 200px;
    }
    .signup {
        margin-left: -3%;
    }
    .signup-container {
        margin-top: 4vh;
    }
    .login {
        margin-left: -3%;
    }
}