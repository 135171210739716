
.forgot-pass__warning {
    color: #F00;
    font-size: 11px;
    margin-top: 3rem;
    margin-bottom: -2rem;
}

.forgot-pass__reset-btn-div {
    margin-top: 2rem;
}

.pass-modal-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(233, 233, 233, 0.75);
    z-index: 100;
}

.login-modal__inner-div {
    width: 100%;
    height: 100%;
}

.forgot-pass_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 2rem 4rem 6rem 4rem;
    border: solid black 1px;
    border-radius: 10px;
    background-color: #B8D9C5;
}

.forgot-pass__exit-btn {
    width: min-content;
    align-self: flex-end;
    background: none;
    border: none;
    margin-right: -2rem;
}

.forgot-pass__header {
    color: #213546;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.6px;
}

.forgot-pass__body {
    width: 80%;
}


.forgot-pass__bold {
    font-weight: 600;
}

.forgot-pass__text-input {
    border: #213546 solid 1px;
    background-color: rgba(255, 255, 255, 0.52);
    width: 100%;
}

.forgot-pass_reset-btn {
    border: none;
    padding: .8rem 3rem;
    border-radius: 5px;
    color: white;
    background: #213546;
}

.forgot-pass__success-p {
    font-size: 18px;
}

@media only screen and (max-width: 1000px){
    .forgot-pass_container {
        width: 70%;
    }
}

@media only screen and (max-width: 700px) {

    .forgot-pass__header {
        font-size: 27px;
    }

    .forgot-pass__text-input {
        margin-top: 5px;
    }

    form {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 530px) {

    .forgot-pass__header {
        font-size: 20px;
    }

}