.internship-info-container {
    padding: 15px;
    background-color: white;
    display: grid;
    grid-template-columns: 1.2fr 1fr 1.3fr 0.5fr 0.5fr;
    grid-template-rows: 1fr;
    cursor: pointer;
    padding-left: 60px;
    gap: 50px;
    border-radius: 10px;
    p {
      font-weight: 700;
      text-align: left;
    }
  }
  
  .internship-info-container:hover {
    background-color: #e2e0e0;
  }
  
  @media screen and (max-width: 1030px) {
    #applicant-item {
      display: none;
    }
    .internship-info-container {
      padding-left: 0;
      gap: 10px;
      padding: 10px;
    }
    .flex-row-container {
      margin-right: 0px;
    }
  }
  
  .flex-row-container {
    display: flex;
    justify-content: space-between;
    gap: auto;
  }
  
  .internship-info-item {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .internship-status-container {
    position: relative;
  }
  .internship-dropdown-status {
    display: none;
    position: absolute;
    top: 100%;
    background: #fff;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .internship-drop-mini-container {
    display: flex;
    justify-content: center;
  }
  
  .internship-status-container:hover .internship-dropdown-status {
    display: block;
    z-index: 99;
  }

@media only screen and (max-width: 600px) {
  .internship-info-container {
    grid-template-columns: 0.2fr 1fr 0.3fr 0.5fr;
  }
}
  