.applicants-root {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    gap: 10px;
    margin-top: 15px;
}

.applicants-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-right: 25px;
    width: 100%;
}

.applicants-header-text {
    font-weight: bold;
    font-size: 24px;
}

.applicants-header-button {
    font-weight: bold;
    cursor: pointer;
}

.applicant-modal-background {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 15% auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 0.09vw solid #888;
    width: 70%;
    z-index: 20;
    align-items: center;
    min-height: 300px;
}

.close-modal-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.applicant-modal-close-button {
    cursor: pointer;
    font-size: 25px;
}


.applicant-modal-body {
    height: 20vw;
    font-size: 1vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    background-color: #f1f1f1;
    border-left: 0.09vw solid black;
    border-right: 0.09vw solid black;
}


.applicant-modal-body-column-one {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7vw;
}

.applicant-modal-body-column-two {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.7vw;
}

.applicant-modal-info-image {
    height: 8.96vw;
    width: 8.96vw;
    max-width: 8.96vw;
}

.applicants-title-container {
    font-size: 1.2vw;
    height: 2.1vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 1vw;
}

.applicants-internship-info-title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.applicant-internship-info-container {
    height: 2.5vw;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    cursor: pointer;
}

.applicant-internship-info{
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu{
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-status{
    font-size: 1vw;
    width: 8vw;
    height: 1.5vw;
}
.edit-status{
    font-size: 1vw;
    width: 8vw;
    height: 1.5vw;
}
.name{
    font-weight: bold;
    font-size: 1.75vw;
    margin-bottom: 1.4vw;
}
.contact-info{
    font-weight: bold;
    font-size: 1.4vw;
}
.contact-app{
    font-weight: light;
    font-size: 0.7vw;
    font-style:italic ;
}

/* Add the following styles to your existing CSS file */

.accordion{
    width: 100%;
}
  .accordion-item-header {
    margin-top: -0.6vw;
    font-size: 1vw;
    height: 3vw;
    cursor: pointer;
    background-color: #f1f1f1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-top: 0.09vw solid black;
    border-left: 0.09vw solid black;
    border-right: 0.09vw solid black;
  }
  
  .accordion-item-body-content {
    line-height: 1vw;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .accordion-item-body-content.active {
    max-height: 100vw; 
  }
  .email-image, .telephone-image, .location-image, .download-image{
    width: 1.68vw;
    height: 1.68vw;
  }
  .eyeopen-image{
    width: 2.24vw;
    height: 2.24vw;
  }
  
  @media (max-width: 1140px) {
    .accordion-item-header {
        margin-top: -1.1vw;
    }
    .edit-status{
        width: 8vw;
        height: 1.5vw;
    }
    .edit-status2{
        height:1.5vw;
    }
  }
  
  @media (max-width: 430px) {
    .applicants-header-text{
        font-size: 3vw;
    }
    .accordion-item-header {
        height: 5vw;
        margin-top: -2.2vw;
    }
    .applicant-internship-info{
        font-size: 1.7vw;
    }
    .edit-status{
        margin-top: -2.2vw;
        width: 13vw;
        height: 0.6vh;
        font-size: 0.7vw;
    }
    .edit-status2{
        width:15vw;
        font-size:1vw;
    }

    .menu{
        font-size: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
 
  