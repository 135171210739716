.hero_title-2 {
    font-size: 40px; /* You may need to adjust this depending on the viewport */
    padding-left: 10%; /* Adjust this value based on your design requirements */
    padding-right: 10%; /* Adjust this value based on your design requirements */
    margin-top: 5%;
    font-weight: bold;
    font-weight: 600;
    margin-bottom: 20px; /* Space between title and subtitle */
    text-align: center;
  }

.profile-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    margin-top: 4%;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #4caf50;
    margin-bottom: 10px;
  }
  
  .profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .roi-img {
    width: 80%;
    height: 60%;
    object-fit: cover;
  }
  
  .profile-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: #ccc;
    margin-left: 13px;
  }

  .hero_subtitle-2 {
    font-size: 16px;
    margin-bottom: 40px; /* Space between subtitle and button */
    text-align: center;
    color: #cdcdcd;
    font-weight: 400;
    width: 80%;
  }

  .challenge-section {
    width: 80%;
    margin: 50px auto;
    text-align: left;
    margin-top: 6%;
  }

  .challenge-section2 {
    width: 80%;
    margin: 30px auto;
    text-align: left;
    margin-top: 1%;
  }
  
  .section-title {
    color: #90ee90;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .section-divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    margin-bottom: 30px;
  }
  
  .section-content {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
    margin-left: 6%;
  }
  
  .challenge-description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .challenge-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
  }
  
  .challenge-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
  }
  
  .challenge-list li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #fff;
    font-weight: bold;
  }
  
  .challenge-list strong {
    font-weight: bold;
    margin: 0 4px;
  }
  
  .challenge-conclusion {
    margin-top: 30px;
  }

  .result-subtitle {
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 15px;
    color: #fff;
  }
  
  .result-item {
    margin-bottom: 30px;
  }
  
  .challenge-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .challenge-list li span {
    margin-right: 4px;
  }
  
  .challenge-list li strong {
    margin: 0 4px;
  }
  
  @media only screen and (max-width: 650px) {
    .hero_subtitle-2 {
      width: 90%;
    }

    .roi-img {
      width: 90%;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .section-content {
      margin-left: 0%;
    }

    .challenge-list li {
      display: block;
      line-height: 1.5;
    }
  
    .challenge-list li strong {
      display: inline-block;
    }
  }

  