.post-position-container {
    background-color: #CDE1D6;
    width: 75%;
    padding: 2em 0;
    margin: 0 auto; /* Centers horizontally */
    display: flex; /* Enables flexbox */
    flex-direction: column; /* Ensures vertical stacking */
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
  }
  
  .post-position-header {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1.5em;
    margin-top: 1%;
  }
  
  .highlight {
    color: #06ad0e;
  }
  
  .post-position-section {
    width: 80%;
    margin: 0 auto;
  }
  
  .post-position-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .post-position-form-group {
    margin-bottom: 1.5em;
    width: 100%;
  }
  
  .post-position-label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.5em;
  }
  
  .post-position-input,
  .post-position-textarea {
    width: 100%;
    padding: 0.5em;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .post-position-checkbox {
    margin-left: 0.5em;
  }
  
  .post-position-textarea {
    height: 160px;
    resize: none;
  }
  
  .post-position-form-inline {
    display: flex;
    gap: 1.5em;
    width: 100%;
  }
  
  .post-position-form-button {
    text-align: center;
    margin-top: 2em;
  }
  
  .post-position-button {
    background-color: #233242;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .post-position-button:hover {
    background-color: #1b2a37;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #CDE1D6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-text {
    color: "black";
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 20px;
  }

  .post-position-checkbox-label {
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: 1em;
    color: #333;
  }
  
  .post-position-checkbox {
    margin-right: 0.5em;
    transform: scale(1.2); 
  }
  
  .post-position-checkbox-description {
    font-size: 12px;
    color: #555;
    margin-left: 1.8em; 
    margin-top: 5px;
  }
  
  @media (max-width: 768px) {
    .post-position-form-inline {
      flex-direction: column;
      gap: 1em;
    }
  }

  @media only screen and (max-width: 600px) {
    .post-position-section {
      width: 100%;
    }
  }