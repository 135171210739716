.blog-card{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    border-radius: 15px;
    width: 400px;
    height: 550px;
}
.w-full{
    width: 100%;
}

.card-description{
    font-weight: 400;
    margin-bottom: 0;
}

.w-50{
    width: 50%;
}
.h-500{
    height: 500px;
}

.h-50{
    height: 50%;
}
.object-fit-contain{
    object-fit: contain;
}
.rounded-lg{
    border-radius: 10px;
}

.h-full{
    height: 100%;
}

.h-screen{
    height: 100%;
}

.h-550{
    height: 550px;
}

.p-60{
    padding: 60px;
}

.p-40{
    padding: 40px;
}

.p-30{
    padding: 30px;
}

.p-screen{
    padding: 150px;
    padding-top: 60px;
}

.background-1{
    background-image: url("../../images/background.svg");
    background-size: cover;
}

.text-center{
    text-align: center;
}

.font-bold{
    font-weight: 600;
}

.justify-center{
    justify-content: center;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-wrap{
    flex-wrap: wrap;
}

.card-title{
    font-size: 18px;
    font-weight: 600;
}

.w-400{
    width: 400px;
}

.items-center{
    align-items: center;
}

.outline-none{
    outline: none;
}

.rounded-lg{
    border-radius: 10px;
}

.p-1{
    padding: 1.5rem;
}

.border-grey{
    border: 1px solid #d1d1d1;
}

.mb-5{
    margin-bottom: 24px;
}

.mb-10{
    margin-bottom: 40px;
}

.mt-100{
    margin-top: 100px;
}

.justify-evenly{
    justify-content: space-evenly;
}

.rounded-xl{
    border-radius: 15px;
}

.bg-white{
    background-color: rgb(218, 218, 218);
}

.rounded-2xl{
    border-radius: 20px;
}

.read-more-button{
    background-color: #213546;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    width: 150px;
    border: 1px solid #d1d1d1;
    font-weight: 600;

    &:hover{
        background-color: #172531;
    }
}


.featured-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
}
.mt-auto {
    margin-top: auto;
}
.pt-0{
    padding-top: 0px;
}
@media screen and (max-width: 860px){
    .section-p{
        padding: 30px !important;
        padding-top: 0px !important;
    }
    .mobile-hidden{
        display: none;
    }

    .mobile-post{
        padding: 30px;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column !important;
    }

    .mobile-header{
        font-size: 24px;
    }

    .mobile-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
    }
    .mobile-width{
        width: 100%;
    }
    .mobile-height{
        height: 750px;
    }
    
}
@media screen and (max-width: 1288px){
    .mobile-p{
        padding: 30px;
    }

    .mobile-button{
        padding: 20px;
        width: 100%;
    }

    .mobile-width{
        width: 100% !important;
    }
}

@media screen and (max-width: 1500px) {
    .mobile-width {
        width: 100%;
    }
    .w-400{
        width: 100%;
    }
}

@media (min-width: 860px) and (max-width: 1500px) {

    .blog-card-md{
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 40px;
        border-radius: xx-large;
        padding: 0px !important; 
    }

    .p-30{
        padding: 60px !important;
    }  
    .md-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
    }
    .blog-card-md-section{
        padding: 60px;
        height: 100% !important;
        width: 50%;
        justify-content: center !important;
    } 
    .md-title{
        font-size: 2.5rem;
    }
    .md-button{
        justify-content: start !important;
        margin-top: 0 !important;
    }
}
