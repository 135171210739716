.profile-div {
  min-width: 100vw;
  width: max-content;
  min-height: 100vh;
  height: max-content;
  padding: 3rem 1rem;
  background-color: white;
  z-index: 12;
}

@media (min-width: 768px) {
  .profile-div {
    width: 100vw;
    height: 100vh;
    padding: 3rem 4rem;
    background-color: white;
    height:auto;
  }
}

.profile__exit-button {
  background: none;
  border: none;
}

.profile__overlay-div {
  position:absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  min-height: 100vh; /* Full height (cover the whole page) */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 12; /* Specify a stack order in case you're using a different order for other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-div__recruiter {
}

/* Recruiter Profile User Styling */

.recruiter-profile-user__main-div {
  display: flex;
  flex-direction: row;
}

.recruiter-profile-user__profile-img {
  height: 8rem;
  width: auto;
  /* height: 7rem;
  width: 7rem; */
}

.recruiter-profile-user__name-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3rem;
}

.recruiter-profile-user__name {
  font-weight: 600;
  font-size: 30px;
}

.recruiter-profile-user__location {
  font-size: 17px;
  display: block;
}

@media (min-width: 768px) {
  .recruiter-subscription__div {
    align-items: center;
  }

  .recruiter-billing__div {
    align-items: center;
  }
}

/*  */

/* shared */

.profile-user__btn {
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 3px;
}

.profile-user__btn--chosen {
  background: none;
  border: none;
  padding: 0;
  font-weight: 600;
  border-bottom: 3px #035da9 solid;
}

.profile__hr {
  color: #c4c4c4;
  width: 100%;
  margin: 0;
}

.profile--headers {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 2rem;
}

.profile--link {
  color: #035da9;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

}

.profile--body {
  font-size: 15px;
  height:30vh;
  overflow-y: auto;
}

.profile__contact-info {
  padding: 0.5rem 0;
}

.profile__svg {
  width: 20px;
  height: 20px;
}
/* Subscription Card */

.subscription-card__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 15rem; */
  padding: 1rem 4rem 2rem 4rem;
  border-radius: 10px;
  border: 1px solid #1daf5c;
}

.sub-card__type {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.sub-card__cost {
  color: #1daf5c;
  font-weight: 600;
  font-size: 35px;
}

.sub-card__per-month {
  font-weight: 600;
  font-weight: 30px;
  margin-top: -8px;
}

/* billing info styling */

.billing-info__info {
  font-weight: 500;
}

.billing-info__row--padding {
  padding: 0.5rem 0;
}

/* Recruiter Profile Body Styling */

.recruiter-profile-body__div {
  background-color: white;
}

/* CompanyProfileUser styling */

.company-profile-user__profile-img-div {
  height: 7rem;
  border-radius: 10px;
  overflow: hidden;
}

.company-profile-user__profile-img {
  height: 7rem;
}

.company-profile-name-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3rem;
}

.company-profile-name {
  font-weight: 600;
  font-size: 30px;
  padding-top: 1rem;
}

.recruiter-profile-user__location {
  font-size: 17px;
  display: block;
}

@media (min-width: 768px) {
  .recruiter-subscription__div {
    align-items: center;
  }

  .recruiter-billing__div {
    align-items: center;
  }
  .list-profile-internships-container{
    height:40vh;
    overflow-y: auto;
  }
}

/* CompanyProfileBody styling */

.company-profile__company-about-div {
  margin-right: 3rem;
}

/* CompanyInternshipTable styling */

@media (min-width: 768px) {
    .company-internship-table_div {
        padding-right: 7rem;
      }
}


.company-internship-table__first-col {
  width: 75%;
}

.company-internship-table__row {
  padding: 1.5rem 2rem;
  font-weight: 500;
  border-bottom: #035da9 solid 1px;
}

.company-internship-table__row:last-child {
  padding: 1.5rem 2rem;
  font-weight: 500;
  border-bottom: none;
}

.company-internship-table__row--head {
  background-color: #035da9;
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
}
