.container {
  background-color: #213546;
  color: white;
  padding: 5px;
  max-width: 500px;
  border-radius: 5px;
  align-self: flex-end;
  display: inline-block;
  word-break: break-word;
  margin-bottom: 30px;
}

.container p {
  margin: 0;
}
