
.external_icon_container{
  display: flex;
  justify-content: space-evenly;
  min-width:10vw;
}

.abcRioButtonContentWrapper {
  display: flex ;
  flex-direction: column;
  width: 4.6rem;
  max-height: 4.1rem !important; 
  overflow: hidden !important;
  border-radius: 200px;
  background: transparent !important;
}
.abcRioButtonIcon {
  width: 100%;
  margin-top: 7%;
  width: auto;
  padding:0 !important;
  background:white;
  z-index: 20;
  display: flex;
  justify-content: center;
  place-items: center;
  margin-left: -92%;
}

#google-sign-in > div{
  height:100% !important;
  width:100% !important;
  border-radius: 5px;
}
#google-sign-in.google_btn{
  height: 35px;
  width: 35px;

}
div.abcRioButtonContentWrapper{
  height: 100%;
  width: 100%;
  position: relative;
  
}
div.abcRioButtonIcon{
  display: flex;
  position: relative;
  top: 8%;
  left: 59%;
}
div.abcRioButtonContentWrapper .abcRioButtonSvgImageWithFallback{
  height:35px !important;
  width:35px !important;
  position: relative;

}

.abcRioButtonIconImage{
  margin:0 !important;
  
}

div.abcRioButtonIcon {
  width: 100% !important;
  display: flex;
  height: 100% !important;
  align-self: center !important;
  justify-self: center !important;
  z-index: 10;
  padding: 0 !important;
}
svg.abcRioButtonSvg {
  position: relative;
  width:25px !important;
  height:25px !important;


}
.abcRioButtonIcon svg {
  margin: 0;
}

@media only screen and (max-width:900px) {
  .external_icon_container{
    display: flex;
    justify-content: space-evenly;
    min-width: 20vw;
  }
 
}