.search-back-button-wrapper {
    position: relative;
    top: 1vh;
    left: 4vw;
    display: inline-flex;
    text-align: center;
    align-self: center;
}

.search-back-button-wrapper img {
    height: 25px;
    width: 30px;
}

.search-back-button > * {
    vertical-align: middle;
}

.search-back-button {
    border: none;
    background: none;
    font-weight: 600;
    font-size: 1.5rem;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px; 
}

.grid-item {
    overflow: hidden;
    background: white;
    position: relative;
    height: 200px;
}

.intern-info {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    margin-top: 2rem;
}

.intern-info h1 {
    margin-left: .8rem;
}

.button-wrapper {
    display: flex;
    position: absolute;
    justify-content: space-around;
    width: 100%;
    bottom: 10px;
}

.edit-button {
    width: 8vw;
    height: 5vh;
    min-width: 113px;
    min-height: 34px;
    border-radius: 5px;
    font-weight: 500;
    border: 1px solid #035da9;
    color: #035da9;
    background: white;
    font-family: Montserrat;
}

.view-button {
    width: 8vw;
    height: 5vh;
    min-width: 113px;
    min-height: 34px;
    border-radius: 5px;
    background: #035da9;
    color: white;
    font-family: Montserrat;
    border: none;
}


@media (max-width: 1050px) {

    .aboutPage {
        width: 10vw;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-item {
        height: 200px;
    }

    .button-wrapper {
        justify-content: center;
        gap: 6vw;
    }
}


@media (max-width: 640px) {

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
        width: 80%
    }

    .button-wrapper {
        justify-content: center;
        gap: 8vw;
    }

}

.modal {
    background: rgb(253, 253, 253);
}

.modal-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.applicant-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin: 0 auto;
}

.left-side-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.left-side-card select {
    max-width: 180px;
    color: #035da9;
    padding: 1rem 0 1rem 1rem;
    font-size: 1.5rem;
    margin-left: 3.8rem;
    border: 1px solid rgb(194, 193, 193);
    border-radius: 6px;
}

.contact-method {
    color: rgb(141, 140, 140);
}

.right-side-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.right-side-card img {
    width: 20px;
    height: 20px;
}

.right-contact-email {
    display: flex;
    gap: 10px;
}

.right-contact-email a {
    text-decoration: none;
    color: black;
}


.right-contact-telephone {
    display: flex;
    align-items: center;
    gap: 10px;
}

.right-contact-location {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: -12px;
}


.resume-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.resume-container button {
    background: none;
    border: none;
}

.resume-download, .resume-view {
    border: 1px solid black;
    border-radius: 20%;
    padding: 1px;
}