.container {
  align-self: flex-start;
  display: flex;
  word-break: break-word;
  align-items: center;
  margin-bottom: 30px;
}

.text {
  margin: 0;
  background-color: white;
  color: black;
  padding: 5px;
  max-width: 500px;
  border-radius: 5px;
  white-space: pre-wrap;
}

.logo {
  width: 40px;
  margin-right: 12px;
}
