@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);

.container{
  width:100%;
  height:100px;
  margin-bottom: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.container input{
  border:1px solid #ccc;
  outline:none;
  width: 100%;
  height:30px;
  border-radius:10px;
  margin:0 auto;
  display:block;
  color:#151515;
  font-family:'open sans', sans-serif;
}

.container input:focus{
  box-shadow: 0 0 8px rgba(72, 193, 249, .4);
}

.container ::-webkit-input-placeholder{
  color:rgba(0,0,0,.5);
}

.sendButton {
  position: absolute;
  border-radius: 15px;
  background-color: #213546;
  color: white;
  font-weight: 500;
  padding: 6px 10px;
  left: 100%;
  top: 50%;
  transform: translate(10px, -50%);
}

.inputContainer {
  width:70%;
  height:36px;
  display:flex;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .sendButton {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
