  .submitted-msg {
      width: 50%;
      text-align: center;
      margin: auto;
    }
  
  .contact-form-title {
      width: 90%;
      min-width: 28rem;
      max-width: 55rem;
      text-align: center;
      margin: auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
      color: black;
      font-weight: 400;
  }
  
  .contact-form {
      width: 90%;
      min-width: 28rem;
      max-width: 55rem;
      text-align: center;
      margin: auto;
      color: black;
      border: 2px solid white;
      padding: 2rem 3.5rem;
      background-color: rgba(255, 255, 255, 0.54);
      border-radius: 25px;
      font-weight: 400;
    }
    
    .contact-form__input {
      border: none;
      border-radius: 5px !important;
    }
    
    .contact-form__text-area {
      font-size: 1.25rem;
      width: 100%;
      border-radius: 5px !important;
    }
    
    .contact-form__dropdown-btn {
      background-color: white;
      color: rgb(96, 96, 96);
      border-radius: 5px !important;
    }
    
    ::placeholder {
      color: black;
      opacity: 1;
    }
    
    #contact-form__btn {
      font-family: 'Montserrat';
      background-color: rgba(3, 93, 169, 1);
      color: white;
      font-weight: 700;
      font-size: 1.25rem;
      padding: .7rem 3rem;
      border-radius: 5px;
      border: none;
      margin-left: -.75rem;
    }
    
    input.form-control {
      height:3rem !important;
    }
  
    .schedule-title {
      margin: 2rem auto 2rem auto;
      text-align: center;
      color: black;
      font-weight: 400;
    }
  
    .schedule-form {
      width: 90%;
      margin: 2rem auto 7rem auto;
      color: black;
      display: flex;
      justify-content: space-around;
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.54);
      border-radius: 25px;
    }
  
    .schedule-description {
      width: 30rem;
      font-weight: 600;
      font-size: x-large;
      margin: 4rem 0 0 2rem;
    }

    .calendly_inner_container {
      width: 60vw;
      min-height: 750px;
      position: relative;
    }
  
    @media only screen and (max-width: 1356px) {
      .schedule-form {
          flex-direction: column;
          justify-content: start;
          align-items: center;
      }
  
      .schedule-description {
          width: 80%;
          font-weight: 600;
          font-size: large;
          margin: 0 auto 0 atuo;
        }

        .calendly_inner_container {
          width: 90%;
          height: 1200px;
          overflow: hidden;
        }
    }
    
    @media only screen and (max-width: 576px) {
      .contact-form__btn-div {
        justify-content: center;
      }
    
      .contact-form__input, .contact-form__text-area {
        font-size: 3vw !important;
      }
    
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
       font-size: 3vw !important;
      }
    
      .contact-form__dropdown-btn {
        font-size: 3vw;
        padding-top: .8rem;
        padding-bottom: .8rem;
      }
    
      #contact-form__btn {
        font-weight: 700;
        font-size: 1rem;
        padding: .7rem 3rem;
        border-radius: 5px;
        border: none;
        margin-left: -.75rem;
      }
  
      .schedule-form {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
      }
  
      .schedule-description {
          width: 90%;
          font-size: medium;
          margin-top: 2rem;
      }

      .calendly_inner_container {
        width: 90%;
        height: 1200px;
        overflow: hidden;
      }
    }
    
    /* *************** none of styling below is used in contact form*/

    button
    {
      overflow: visible;
    }
    
    ::placeholder
    {
      color:black;
      opacity: 0.6;
    }
    
    
    .contact-form .btn input {
      margin-top: 10px;
    }
    