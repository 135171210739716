.container {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background-color: #d7ece0;
  left: 50%;
  top: 50px;
  transform: translate(-50%, 0);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}


.back {
  border: none;
  background-color: transparent;
  align-self: end;
}

@media only screen and (max-width: 900px) {
  .container {
    width: 100vw;
    height: 100vh;
    top: 0;    
  }
}
