.container {
  background-color: #f6fbf8;
  border-radius: 10px;
  padding: 5px 10px;
  max-width: 700px;
  width: 100%;
}

.button {
  background: none;
  color: 7b7e7c;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 8px 0;
  display: block;
}
