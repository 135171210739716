.navbar {
    display: contents;
    position: relative;
}

.top-bar {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 4vw 0 1vw;
    height: 11vh;
    width: 100%;
    background-color: #CDE1D6;
    z-index: 10;
}

.top-bar .mobile-menu {
    display: none;
}

.top-bar .left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top-bar .left-container .logo {
    width: 14rem;
    background-size: contain;
    transform: translateY(-0.5rem);
}

.top-bar .left-container .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 0 1rem;
    border-radius: 100px;
    width: 20vw;
    margin: 0 1rem;
}

.top-bar .left-container .search img {
    height: 1.25rem;
    background-size: contain;
    margin-right: 0.5rem;
}

.top-bar .left-container .search input {
    font-size: 0.9rem;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

.top-bar .left-container .search p {
    font-size: 0.9rem;
    color: #B5B5B5;
    font-family: 'Montserrat', sans-serif;
}

.top-bar .left-container .filter {
    width: 1.5rem;
    background-size: contain;
    margin-left: 16vw;
}

.nav-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: row;
    list-style: none;
    margin-left: 1rem;
    position: relative;
}

.top-bar ul li {
    list-style: none;
    margin-left: 1rem;
}

.top-bar ul .arrow-container {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-left: 1rem;
}

.top-bar ul .arrow-container img {
    margin-left: 0.3rem;
    position: relative;
}

.top-bar ul li a {
    color: black;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.side-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 11vh;
    left: 0;
    height: 100%;
    width: 9rem;
    padding-top: 2rem;
    background-color: #213546;
    z-index: 5;
    border: none;
}

.navbar__img {
    width: 3rem;
    margin: 1rem 0 0 0;
}

.sidebar-item p {
    color: white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;

}

.sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
    background: none;
    border: none;
    cursor: pointer;
}

.hamburger {
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.sidebar-label{
    margin-top: 5px;
}

@media only screen and (max-width: 900px) {
    .top-bar ul {
        display: none;
    }

    .top-bar.expanded ul {
        display: block;
        position: absolute;
        top: 6vh;
        left: 0;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        height: calc(100vh - 6vh);
        background-color: white;
        margin: 0;
        padding: 20px;
        z-index: 10;
    }

    .top-bar ul li {
        padding-top: 1rem;
        margin: 0 0 0 1rem;
    }

    .top-bar ul li a {
        color: rgb(0, 0, 0);
    }

    .top-bar ul .arrow-container img {
        display: none;
    }

    .top-bar .left-container .search {
        display: none;
    }

    .top-bar .mobile-menu {
        display: flex;
    }

    .top-bar {
        height: 6vh;
    }

    .top-bar .left-container .logo {
        width: 8rem;
        transform: translateY(-0.15rem);
    }

    .side-bar {
        display: none;
    }

    .side-bar.expanded {
        display: flex;
        background-color: white;
        position: absolute;
        align-items: baseline;
        width: 100%;
        height: fit-content;
        margin: 0;
        padding: 15px 20px;
        top: 15.5rem;
        border-top: rgb(60, 60, 60) solid 0.5px;
        border-bottom: rgb(60, 60, 60) solid 0.5px;
        z-index: 11;
    }

    .navbar__img {
        display: none;
    }

    .sidebar-item p {
        color: black;
        margin: 0 0 0 1rem;
    }

    .sidebar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 2rem;
        padding: 1.2rem 0;
        margin: 0;
    }

    .top-bar ul .arrow-container {
        position: absolute;
        ;
        top: 22rem;
        background-color: white;
    }

}

.arrow-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    list-style: none;
    top: 45px;
    right: 1px;
    background-color: #213546;
    min-width: 125px;
    z-index: 1;
    list-style: none;
    padding: 10px;
    margin: 0;
}

.dropdown-content a {
    padding: 10px;
    display: flex;
    text-decoration: none;
    color: #fff;
}
