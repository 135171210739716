.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
  }
  
  .modal-content {
    max-height: 80%; 
    overflow-y: auto;
    background-color: #CDE1D6;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .file-upload-area {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    width: 90%;
  }
  
  .file-upload-area input {
    /* display: none; */
  }
  
  .file-upload-area p {
    color: #666;
    margin: 0;
  }
  
  .upload-submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .upload-submit-button:hover {
    background-color: #0056b3;
  }

  .parsedResult-inputGroup {
    margin-bottom: 15px;
    font-size: 12px;
  }
  
  .parsedResult-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .parsedResult-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 12px;
    box-sizing: border-box;
  }
  
  .parsedResult-previewButton {
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #555;
  }
  
  .modal-content.blurred {
    filter: blur(5px); 
    pointer-events: none; 
  }