.jobModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .jobModal-content {
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .clipboard-icon {
    color: #56C385; /* Green color */
  }
  
  .jobModal-container {
    background-color: #cde1d6; /* Light green background */
    border-radius: 15px;
    padding: 2rem;
    width: 40%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .jobModal-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 3.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .jobModal-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .jobModal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #233242;
    margin-bottom: 0.5rem;
  }
  
  .jobModal-subtitle {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .jobModal-link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .jobModal-link {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .jobModal-copy-button {
    background-color: #7ac903; /* Green background */
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .jobModal-copy-button:hover {
    background-color: #659f02;
  }

  @media only screen and (max-width: 600px) {
    .jobModal-container {
      width: 90%;
    }
  }