.dashboard-container {
  padding: 20px;
  background-color: #cde1d6;
  min-height: 100vh;
}

.glass-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.flex-row-container-header2{
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  margin-bottom: 20px;
  .post{
      margin-left: auto;
  } 
  h1{
      margin-bottom: 0;
      text-align: left;
      line-height: 1.5;
      font-weight: 700;
  }   
 }

 .button-container {
    display: flex;
    gap: 10px;
 }

 .dashboard-internship-title-container2 {
  height: 20px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr 1fr; /* Adjusted column sizes */
  gap: 30px;
  grid-template-rows: 1fr;
}

.dashboard-internship-info-title:last-child {
  margin-left: 30%; /* Add margin to move "Interview Results" to the right */
  text-align: left; /* Optional to align the text within the column */
}

.back-button-applicantList {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.sub-title {
  font-size: 14px;
  color: #1DAF5C;
  font-weight: bold;
  margin-bottom: 30px;
}

.dashboard-internship-title-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
}

.dashboard-divider-line {
  margin: 10px 0;
}

.dashboard-internships-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-internship-row {
  display: grid;
  grid-template-columns: 0.8fr 1.0fr 1.8fr 1.5fr 1.2fr;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-internship-info {
  text-align: left;
  font-size: 14px;
}

.status-applied {
  color: #555;
  font-weight: bold;
}

.view-results-button {
  background-color: #233242;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inline-modal {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 100%; /* Adjust width as needed */
  z-index: 1; /* Ensure it appears on top */
}

.modal-grid {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Left column 1fr, Right column 2fr */
  gap: 20px; /* Space between columns */
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Spacing between items */
}

.right-column {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spacing between items */
  overflow-y: auto; /* Scroll if content overflows */
  max-height: 80vh; /* Prevent overflowing modal */
}

.modal-content2 {
  background-color: #fefefe;
  flex-direction: column;
  padding: 10px;
  /* width: 30%; */
  z-index: 20;
  align-items: center;
}

.date-text {
  font-size: 12px;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.location-status {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.location-icon {
  width: 16px; /* Adjust the width as needed */
  height: 16px; /* Adjust the height as needed */
  margin-right: 8px; /* Spacing between the icon and the status text */
}

.hire-reject-buttons {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
  margin-top: 10px; /* Add some spacing from the element above */
}

.hire-button {
  background-color: #4caf50; /* Green background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.hire-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.reject-button {
  background-color: #f44336; /* Red background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.reject-button:hover {
  background-color: #e53935; /* Slightly darker red on hover */
}

.loaderForInterviewSent {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-email-sent {
  display: flex !important;
  align-items: flex-start !important;
}

input[type="checkbox"] {
  cursor: pointer;
}

.qa-question-text-qbqAnalysis {
  font-size: 14px; /* Same as accordion-body text */
  margin: 0; /* Remove any default margin */
  font-weight: 400; /* Bold for emphasis */
}

.accordion-button-qbqAnalysis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background-color: transparent; /* Transparent background */
  border: none; /* Remove button border */
  padding: 10px 15px; /* Consistent padding */
  font-size: 16px; /* Match font size */
  transition: background-color 0.2s ease; /* Smooth hover */
}

.accordion-button-qbqAnalysis:hover {
  background-color: #f9f9f9; /* Light hover background */
}

.qa-toggle-icon-qbqAnalysis {
  margin-left: 10px; /* Space between question and icon */
  width: 16px; /* Icon size */
  height: 16px; /* Icon size */
}

.accordion-item-qbqAnalysis {
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.accordion-body-qbqAnalysis {
  font-size: 14px;
  line-height: 1.5;
}

.accordion-container-qbqAnalysis {
  margin: 5px 0;
  margin-left: -10px;
}

.accordion-header-qbqAnalysis {
  margin-bottom: 10px;
}

.accordion-collapse-qbqAnalysis {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
}

.dashboard-add-job-button4 {
  background-color: #4caf50; /* Green background */
  margin-left: auto;
  color: white; /* White text */
  font-weight: bold; /* Bold text */
  font-size: 16px; /* Text size */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Spacing inside button */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.dashboard-add-job-button4:hover {
  background-color: #3e8e41; 
} 

.download-candidates-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #e0e0e0; /* Light gray background */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  color: #000; /* Black text */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth hover effect */
  margin-right: 10px;
}

.download-candidates-button:hover {
  background-color: #d6d6d6; /* Darker gray on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Slightly larger shadow */
}

.download-candidates-button:active {
  transform: translateY(2px); /* Button press effect */
}

.download-candidates-button img {
  width: 18px;
  height: 18px;
}

.kanban-dashboard-internship-title-container2 {
  display: flex;
  gap: 42px;
  padding: 10px 0;
  border-bottom: 2px solid #ccc;
}

.kanban-dashboard-tab {
  background: #f9f9f9;
  border: none;
  padding: 5px 35px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.2s ease-in-out, color 0.2s;
}

.kanban-dashboard-tab:hover {
  background: #dcdcdc;
}

.kanban-dashboard-tab.active {
  background: #1f2937; /* Dark navy shade for active tab */
  color: white;
  font-weight: bold;
}

.kanban-applicant-row {
  width: 100%;
  background-color: white;
  border: 8px solid #e0e0e0;
  border-radius: 3px;
  padding: 8px 8px 8px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  display: flex;
  flex-direction: column;
}

.kanban-applicant-row:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}

.kanban-applicant-row.selected {
  border: 2px solid #4CAF50; 
  background-color: #E8F5E9; 
}

.kanban-applicant-container {
  width: 35%; 
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}

.kanban-applicant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
}

.kanban-applicant-name {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.kanban-applicant-location {
  color: #333;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 40px;
}

.kanban-applicant-links {
  color: #333;
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.kanban-applicant-link {
  text-decoration: underline;
  font-size: 14px;
  color: #333;
}

.kanban-applicant-date {
  font-size: 12px;
  color: #888;
}

.kanban-applicant-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.kanban-applicant-selector input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.kanban-applicant-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-bottom: 16px;
}

.kanban-select-all {
  display: flex;
  align-items: center;
  gap: 10px;
}

.kanban-select-all input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.kanban-date-sort {
  font-size: 14px;
  color: #666;
}

.candidate-action-container {
  display: flex;
  height: 62px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  margin-left: 10px;
}

.candidate-status-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.candidate-action-buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.push-to-resume-screening {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.push-to-resume-screening:hover {
  background-color: #e0e0e0;
}

.reject-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.reject-button:hover {
  background-color: #d32f2f;
}

.candidate-details-container {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-left: 10px;
}

.candidate-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.candidate-contact-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-detail-row {
  display: flex;
  align-items: center;
}

.contact-label {
  flex-basis: 100px;
  font-weight: 500;
  color: #666;
}

.contact-value {
  color: #333;
}

.kanban-resume-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa; /* Light gray background for contrast */
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.kanban-resume-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.kanban-resume-container iframe {
  width: 100%;
  height: 600px;
  border-radius: 5px;
  border: none;
}

.kanban-score-badge {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 8px;
  min-width: 40px;
  text-align: center;
}

/* If the candidate has an overall score */
.kanban-score-badge.scored {
  background-color: #4caf50; /* Green */
  color: white;
}

/* If the candidate's score is missing, show "Awaiting" */
.kanban-score-badge.awaiting {
  background-color: #ccc; /* Gray */
  color: black;
}

.resume-score-badge {
  display: inline-block;
  border: 1px solid #333;      
  border-radius: 8px;         
  padding: 4px 8px;            
  font-weight: 600;         
  font-size: 14px;            
  color: #333;                
  background-color: #fff;      
  margin-left: 8px;       
  position: relative;
  top: -10px;     
}

.contact-pill {
  display: inline-flex;
  align-items: center;
  background-color: #e4f3e8; /* or whatever color you prefer */
  padding: 0.5rem 1rem;
  border-radius: 10px; /* super-rounded for a "pill" look */
}

.contact-icon {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem; /* spacing between icon and text */
}

@media only screen and (max-width: 600px) {
  .dashboard-internship-title-container2 {
    height: 35px;
    grid-template-columns: 2.5fr 1fr 3.6fr; 
  }

  .dashboard-internship-row {
    grid-template-columns: 2.8fr 8.0fr 1.8fr;
  }

  .date-text {
    margin-bottom: 0px;
  }

  .modal-grid {
    grid-template-columns: 1fr; /* Stack the columns for mobile */
    gap: 10px; /* Adjust spacing for mobile */
  }

  .right-column {
    margin-top: 20px; /* Add space between left and right content */
  }
}