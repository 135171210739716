.press_page{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat';
}
.header_section{
    width:90vw;
    min-height:25vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.bottom_section{
    width:90vw;
    min-height:10vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.header_section > h1{
    font-weight:600;
    font-size: 50px;
}
.header_section > h4, .bottom_section > h4{
    font-weight: 500;
}
.press_page .press_content_container{
    min-width:90vw;
    display:grid;
    grid-template-rows:2fr;
    grid-template-columns:repeat(3,1fr);
    row-gap: 50px;
    column-gap: 50px;
    margin-bottom: 50px;
    /* border:solid palegoldenrod 2px; */
}
.press_page .press_content_container .img_container{
    width:29.3vw;
    height:40vh;
    /* border:solid 2px green; */
}
.press_page .press_content_container .img_container img{
    object-fit: fill;
    max-width: 100%;
    height:90%;
    /* width:100%; */
    /* border: solid 5px black; */
}
.press_page .press_content_container .img_container figcaption{
    font-size:20px;
    font-weight: 500;
}
.press_page .press_content_container .grid_item{
    background-color: transparent;
    width:29.3vw;
    /* border:solid 2px palegreen; */
}
.press_page .press_content_container .grid_item p{
    font-weight: 500;
    font-size:30px;
}
.press_page .press_content_container a {
    text-decoration: none;
}
.press_page .press_content_container a .button{
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 220px;
    height: 55px;
    margin: 16px 0;
    justify-content: center;
}
.press_page .press_content_container a .button p{
    margin: auto;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width:1080px){
    .press_page .press_content_container{
        grid-template-rows:2fr;
        grid-template-columns:repeat(2,1fr);
        /* border:solid palegoldenrod 2px; */
    }
    .press_page .press_content_container .grid_item{
        width:45vw;
        /* border:solid 2px palegreen; */
    }
    .press_page .press_content_container .img_container{
        width:45vw;
        height:35vh;
        /* border:solid 2px green; */
    }
}
@media only screen and (max-width:650px){
    .press_page .press_content_container{
        grid-template-rows:4fr;
        grid-template-columns:repeat(1,1fr);
        /* border:solid palegoldenrod 2px; */
    }
    .press_page .press_content_container .grid_item{
        min-width:100%;
        /* border:solid 2px palegreen; */
    }
    .press_page .press_content_container .img_container{
        width:100%;
        height:35vh;
        /* border:solid 2px green; */
    }
    .header_section > h1{
        font-size: 40px;
    }
    .header_section > h4, .bottom_section > h4{
        font-size:20px;
    }
    .bottom_section{
        margin-bottom:20px;
    }
    .press_page .press_content_container .img_container figcaption{
        font-size:15px;
    }
    .press_page .press_content_container .grid_item p{
        font-size:25px;
    }
    .press_page .press_content_container a .button{
        width: 150px;
        height: 45px;
    }
    .press_page .press_content_container a .button p{
        font-size: 15px;
        font-weight: 600;
    }
    .press_page .press_content_container .img_container img{
        object-fit: fill;
        width: 100%;
    }
}
@media only screen and (max-width:400px){
    .header_section > h4, .bottom_section > h4{
        font-size:15px;
    }
}
