/* 
     .homepage_footer{
      background-color: #213546;
      height: 8vh;
      width:100%;
      display: flex;
      color: rgb(255, 255, 255);
    
    }
    
    .footer_grid{
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%; 
      padding-top:0;
      margin-top:0;
    }
    .footer_nav{
      display: none;
      height:0;
    }
  
    .footer_subfooter{
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding:0 30px;

    }
    .footer_subfooter a, .footer_subfooter p{
      display: inline-block;
      margin: 0 10px;
      color: rgb(255, 255, 255);

    }
    .footer_logo{
      width:120px;

    }
    .footer-right{
      width:50%;
      display: flex;
      justify-content: space-evenly; 
      align-items:center;
      font-size:1rem;
    }
    .logo-container{
      display: flex;
      align-items:center;
      width:40%;
      flex-direction: row;
    }
    .navbar__social{ 
      color:white;
    }
    .footer-social-icons{
     display:flex;
     flex-direction:row;
      width:15vw;
      align-items:center;
 justify-content: space-around;
    }
    .sc-icons{
      font-size:36px;
      height:2.5rem !important;
      width:2.5rem !important;
    }

    .sc-icons-tiktok{
      font-size:30px;
      height:2rem !important;
      width:2.5rem !important;
    }
    .footer-right .noSmall a{
      text-decoration: none;
    }
    @media only screen and (max-width:850px){
      .footer-right .noSmall{
        display:flex;
        flex-direction: column;
        float:left;
      }
    }
    @media only screen and (max-width: 635px)  {
  .footer_subfooter{
    width:100%;
    justify-content:space-between;
    overflow:hidden;
    padding:0;
  }
  .footer-right .noSmall{
    display:none;
  }
  .logo-container{
    width:40%;
    flex-direction:column;
    height:100%;
   justify-content:center;
   margin-right:1rem ;
   margin-left:1rem;
  }
  .logo-container > p {
    margin-top:0 !important;
  }
  .footer-social-icons{
    width:60%;
    justify-content:center;
    margin-right:5px;
  }
  .footer-right{
    width:40%;
    justify-content:space-between;
  }
  .footer-right a {
    text-align:center;
  }
  .footer-social-icons {
    justify-self:flex-end;
    width:100%;
  }
}

@media only screen and (max-width: 335px)  { 
  .logo-container{
    width:25%;
  }
  .footer_logo{
    width:100%;
  }
  .logo-container p{ 
    font-size:.6rem;
  } 
  .footer-social-icons{
    width:40%;
  }

}  */
.homepage_footer{
  width:100vw;
  background-color: #000;
  display:flex;
  margin-top: 5%;
  flex-direction: column;
}
.homepage_footer > .footer-line{
  align-self: center;
  margin-top:30px;
  width:95%;
  background-color:#ECF1F499  ;
  height:1px;
  margin-bottom:5vh;
}
.homepage_footer > .footer-content{
  display:flex;
}
.homepage_footer > .footer-content > .footer-logo-container{
  width:50%;
}
.homepage_footer > .footer-content > .footer-logo-container > .footer_logo{
  width: 350px;
  margin-left: 3vw;
}


.homepage_footer > .footer-content > .footer-content-columns-contain{
  width:50%;
  display:flex;
  justify-content: space-around;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns{
  color:white;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > h3{
  font-size:calc(15px + 0.25vw);
  font-weight: 600;
  margin-bottom:15px;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > p > a {
  text-decoration: none;
  color:white;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
  display:none;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > p{
  font-size:calc(11px + 0.25vw);
}
.homepage_footer > .footer-links {
  display:flex;
  width:95%;
  align-self: center;
  color:white;
  margin-top:7vh;
  margin-bottom:5px;
}
.homepage_footer > .footer-links > .footer-doc{
  margin:0;
  padding:0;
  width:70%;
  display: flex;
  font-size:calc(13px + 0.25vw);
  align-items: center;
}

.homepage_footer > .footer-links > .footer-doc > .footer-doc-txt{
  display:flex;
}
.homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > p{
  margin:0;
  padding-left:10px;
}
.homepage_footer > .footer-links > .footer-doc > a {
  text-decoration: none;
  color:white;
  margin-left:35px;
}
.homepage_footer > .footer-links > .footer-social-icons{
  width:30%;
  display: flex;
  justify-content: flex-end;
  color:white;
}
.homepage_footer > .footer-links > .footer-social-icons > a{
  color:white;
  width:50px;
}
.homepage_footer > .footer-links > .footer-social-icons > a > .sc-icons{
  height:2.5rem !important;
  width:2.5rem !important;
}

@media only screen and (max-width:900px){
  .homepage_footer > .footer-content{
    display:flex;
    flex-direction: column;
  }
  .homepage_footer > .footer-content > .footer-logo-container{
    width:100%;
    margin-bottom:25px;
  }
  .homepage_footer > .footer-content > .footer-logo-container > .footer_logo{
    width:35vw;
    min-width:150px;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain{
    width:100%;
    flex-wrap: wrap;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns{
    width:50%;
    padding-left:5vw;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
    display:flex;
    height:auto;
    align-items: flex-end;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small > a{
    color:white;
    margin-right:19px;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small > a > .sc-icons{
    height:5vw !important;
    width:5vw !important;
    min-height:25px !important;
    min-width:25px !important;
  }
  .homepage_footer > .footer-links > .footer-social-icons{
    display:none;
  }
  .homepage_footer > .footer-links{
    width:100%;
    margin-top:25px;
  }
  .homepage_footer > .footer-links > .footer-doc {
    flex-direction: column;
    width:100%;
    align-items: start;
    margin-bottom:10px;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt{
    padding-left:5vw;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > p{
    padding-left: 0;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > .right-text{
    padding-left: 10px;
  }
  .homepage_footer > .footer-links > .footer-doc > a{
    margin-left:0;
    padding-left:5vw;
    font-size: calc(11px + 0.25vw);
    margin-top:5px;
  }
}

@media only screen and (max-width:600px){
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
    margin-top: 10px;
  }
} 