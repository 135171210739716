.loaderContainer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    align-self: flex-start; /* Ensure it aligns with the bot messages */
  }
  
  .logo {
    width: 40px;
    margin-right: 12px;
  }
  
  .dots {
    font-size: 24px;
    font-weight: bold;
    color: black;
    display: flex;
  }
  
  .dots span {
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .dots span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dots span:nth-child(3) {
    animation-delay: 0.6s;
  }
  