.post-script-container {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    width: 90%; /* Adjust width to match screenshot */
    max-width: 1200px; /* Set a maximum width for responsiveness */
    background-color: #ffffff; /* White background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px 30px;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .script-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .bucket {
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .bucket-header {
    display: flex;
    font-size: 18px;
    font-weight: bold;
  }

  .remove-icon {
    background: none;
    border: none;
    color: #333;
    margin-left: auto; 
  }

  .add-question-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
    border: 2px solid #1daf5c; 
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .add-question-button .plus-icon {
    color: #1daf5c; /* Green text */
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }
  
  .add-question-button:hover {
    background-color: #eaf8ed; /* Light green background on hover */
  }
  
  .bucket-body {
    margin-top: 10px;
  }
  
  .bucket-prompt {
    font-size: 16px;
  }

  .bucket-name {
    display: inline-block;
    margin-left: 15px;
    border: 1px solid #000; /* Adjust the color as needed */
    border-radius: 8px; /* Rounded corners */
    padding: 4px 10px; /* Adjust padding for spacing */
    font-size: 14px; /* Font size as requested */
    font-weight: 500; /* Semi-bold to match the design */
  }
  
  .additional-questions {
  }
  
  .additional-question {
    display: flex;
    background-color: #f1f1f1;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  .question-text {
    margin-left: 20px;
  }

  .example {
    font-size: 12px; /* Adjusted font size for readability */
    color: #6c757d; /* Gray text color */
    margin-left: 30px;
  }
  
  .post-script-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .post-script-button {
    background-color: #213546;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .post-script-button:hover {
    background-color: #172633;
  }

  .edit-question {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
  
  .edit-input {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 60%;
  }
  
  .save-button,
  .cancel-button {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
  }

  .new-question {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .new-question-input {
    flex: 1;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }