.w-full{
    width: 100%;
}

.h-screen{
    height: 100vh;
}

.p-40{
    padding: 40px;
}

.p-screen{
    padding: 100px;
    padding-top: 60px;
}

.background-1{
    background-image: url("../../images/background.svg");
    background-size: cover;
}

.text-center{
    text-align: center;
}

.font-bold{
    font-weight: 600;
}

.justify-center{
    justify-content: center;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.w-400{
    width: 400px;
}

.items-center{
    align-items: center;
}

.outline-none{
    outline: none;
}

.rounded-lg{
    border-radius: 10px;
}

.p-1{
    padding: 1.5rem;
}

.border-grey{
    border: 1px solid #d1d1d1;
}

.mb-5{
    margin-bottom: 24px;
}

.justify-evenly{
    justify-content: space-evenly;
}



@media screen and (max-width: 768px){
    .mobile-hidden{
        display: none;
    }

    .mobile-p{
        padding: 40px !important;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 1380px){
   .mobile-padding {
    padding: 0;
    padding-left: 0px !important;
    padding-right: 0px !important;
   }

   .glass-container{
    background-color: rgba(255, 255, 255, 0.30); 
    backdrop-filter: blur(5px);
    margin-top:0px !important;
    margin-bottom: 0px !important;
    border-radius: 10px;
    border: none !important;
   }

   .gap{
    gap: 50px;
   }
}


.blog-details{
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rounded-img{
    border-radius: 25px;
    border-color: black;
}

.img-lg{
    height: 500px;
}

.px-40{
    padding-left: 100px;
    padding-right: 100px;
}

.bg-wallpaper{
    background-image: url(http://localhost:3000/static/media/background.cf1dbffb5080aae797b8.svg);
}

.gap-5{
    gap: 50px;
}

.text-bold{
    font-weight: bold;
}
.text-xl{
    font-size: x-large;
}

.mt-5{
    margin-top: 50px;
}   

.back-btn{
    margin-bottom: 30px;
    &:hover{
        cursor: pointer;
    }
}


.text-left{
    text-align: left;
}

.text-blue{
    color: #1e96ec;
}

.px-60{
padding-left: 60px;
padding-right: 60px;
}

.glass-container{
    background-color: rgba(255, 255, 255, 0.30); 
    backdrop-filter: blur(5px);
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 25px;
    border: none;
}

.text-2xl{
    font-size: xx-large;
}
