* {
    box-sizing: border-box;
}

body {
    background-color: #CDE1D6;
    background-size: cover;
    margin: 0;
    font-size: 14px;
}


.about-box {
    width: 95%;
    position: relative;
    border: 1px solid white;
    border-radius: 22px;
    margin: 2.5rem auto;
    padding: 75px 0;
    /* min-width: 95vh; */
    min-height: 90vh;
    background: rgba(250, 250, 250, 0.336);
}

.about-flex-header {
    display: flex;
    justify-content: center;
    /* border: 1px solid blue; */
    text-align: center;
    font-weight: 500;
    font-size: .8em;
    /* width: 90%; */
}

.about-header-item {
    /* border: 1px solid red; */
    align-self: center;
    /* width: 100%; */
    min-width: 250px;
}

.about-you {
    color: #06ad0e;
}

.about-circle1 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    /* margin-left: 50px; */
    background: #7ac903;
    border: 2px solid #06ad0e;
    
}

.about-circle2 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    /* margin-left: 50px; */
    border: 2px solid black;
}

.about-line {
    position: relative;
    margin-top: -29px;
    margin-left: -108px;
    margin-right: -120px;
    border: 1px solid black;
}

.about-h1 {
    margin-bottom: -15px;
}

.about-section {
    margin: 3em auto;
    width: 90%;
    /* border: 1px solid blue; */
    /* height: 100vh; */
    padding: 0 3em 0 0;
    /* overflow: hidden; */
}


.about-form {
    display: flex;
    flex-direction: column;
    /* border: 1px solid orange; */
}

.about-form-group {
    padding-top: 1.5em;
}

.about-form-group label {
    font-weight: 500;
}


.about-label, .about-input1 {
    display: block;
}

.about-input1, .about-select1 {
    border-radius: 7px;
    border: 1px solid rgb(192, 191, 191);
}

.about-input1 {
    width: 45%;
    height: 2em;
}

.about-select1 {
    width: 45.2%;
    height: 2em;
}

.about-input1:focus, .about-select1:focus {
    outline: none;
}

.about-input2, .about-select2 {
    display: none;
}

.about-form-button {
    display: flex;
    position: absolute;
    bottom: 3%;
    right: 4rem;
    width: 12%;
    /* border: 1px solid black; */
    text-align: center;
}

.about-button {
    background-color: rgba(7, 53, 179, 0.795);
    color: white;
    padding: 1rem 3rem;
    border-radius: 6px;
    border: none;
    width: 100%;
    min-width: 120px;
    height: 60px;
}



@media only screen and (max-width: 525px) {

    .about-container {
        overflow: hidden;
    }

    .about-box {
        margin: 1em auto 0 auto;
        min-height: 95vh;
        padding: 50px 0;
    }

    .about-flex-header {
        font-size: .5em;
    }

    .about-header-item {
        min-width: 150px;
        position: absolute;
    }

    .about-header-item .about-you {
        margin-right: 160px;
    }

    .about-header-item .about-work-authorization {
        margin-left: 200px;
    }

    .about-circle1 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: -84px;
    }

    .about-circle2 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: 88px;
    }

    .about-line {
        margin-top: -20px;
        margin-left: -108px;
    }


    .about-section {
        width: 109.5%;
        padding-left: 20px;
        margin: 4em auto;
    }

    .about-h1 {
        margin-bottom: 2px;
    }

    .about-form {
        width: 100%;
        margin: 0 auto;
    }

    .about-form-group {
        padding-top: .5em;
    }

    .about-label {
        display: none;
    }

    .about-input1, .about-select1 {
        display: none;
    }

    .about-input2, .about-select2 {
        display: block;
        width: 100%;
        border: 1px solid rgb(73, 71, 71);
        border-radius: 10px;
        background: none;
        height: 2.5em;
        padding-left: 13px;
    }

    .about-select2 {
        padding-left: 8px;
        color: rgb(114, 113, 113);
    }

    .about-input2:focus, .about-select2:focus {
        outline: none;
    }

    .about-form-button {
        bottom: 7%;
        right: 6.5rem;
    }

    .about-button {
        padding: 0;
        height: 30px;
    }
}