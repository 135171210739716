.container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  height: 80%;
  overflow-y: scroll;
}

.logo {
  width: 75px;
  margin-bottom: 50px;
}

.greeting {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0px 25px;
  }
}
