form {
  margin: 0;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  /* -webkit-appearance: none; */
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  border: 0.15em solid currentColor;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:checked {
  background-color: #8EC7A6;
  border-color: #1daf5c;
}

input[type="radio"]:checked  {
  background-color: #8EC7A6;
  border-color: #1daf5c;
}

.apply-page-long .question-title  {
  display: block;
  margin: 1rem 0 0.8rem 0; 
  font-size: 1.3rem;
  line-height: 17px;
}


.internship-details-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "left right"
    "left apply";
  width: 100%;
}

.internship-details-grid-left {
  grid-area: left;

}

.internship-details-grid-right {
  grid-area: right;
}

.internship-review-edit-text {
  color: #035DA9;
}

.internship-review-edit-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.internship-review-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  width: 100%;
  row-gap: 20px;
}

.internship-review-details-grid-left {

}

.internship-review-details-grid-right {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.internship-review-details-grid-right-remaining {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.internship-review-full-details-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.apply-btn-wrapper {
  grid-area: apply;
}

.apply-page-long select {
  width: 80%;
  margin-top: 0.5rem;
  margin-bottom: 0;
  background: #f7f7f7;}

.apply-page-long input {
  width: 80%;
}

.apply-page-long textarea {
  width: 80%;
}

/* Application Modal */
.apply-wrapper {
  color: black;
  display: flex;
  flex-direction: column;
}

.apply-header {
  display: flex;
  border-bottom: 1px solid rgb(167, 167, 167);
  padding: 15px 0;
}

.apply-progress {
  width: 100%;
  background-color: white;
  height: fit-content;
  align-self: center;
  margin: 50px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.apply-progress-circle {
  background-color: white;
  border: 1px solid #213546;
  border-radius: 291px;
  width: 0.9vw;
  height: 0.9vw;
  margin: 0 auto;
}
.apply-progress-line {
  background-color: #213546;
  width: 11vw;
  height: 1px;
  justify-self: center;
  align-self: flex-start;
  margin: 7px 1px 0;
}
.apply-progress > span {
  width: 3vw;
  text-align: center;
  align-self: flex-start;
}
.apply-step {
  margin-top: 15px;
  width: 10rem;
  font-size: 0.8rem;
}
.apply-progress-active > div {
  background: #8ec7a6;
  border: 2px solid #1daf5c;
}
.apply-progress-active > p {
  color: #1daf5c;
}

span {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items:center;
}

.apply-btn-wrapper {
  align-self: flex-end;
  max-width: 40%;
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: auto;  
}
.apply-btn-wrapper .backbtn {
  width: 10vw;
  height: 5vh;
  min-width: 113px;
  min-height: 34px;
  background: white;
  border-radius: 5px;
  border-color: #035da9;
  color: #035da9;
  font-family: Montserrat;
  font-style: normal;
  font-size: 2vh;
  line-height: 29px;
  margin-right: 1.5rem;
}
.apply-btn-wrapper .nextbtn {
  width: 10vw;
  height: 5vh;
  min-width: 113px;
  min-height: 34px;
  background: #035da9;
  border-radius: 5px;
  border: none;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-size: 2vh;
  line-height: 29px;
}

.nextbtn:disabled {
  background: #acacac;
}

@media (max-width: 770px) {
  .internship-details-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 3fr 0px;
    grid-template-areas:
      "left"
      "right"
      "apply";
    width: 100%;
  }
  
  .internship-details-grid-left {
    grid-area: left;
  }
  
  .internship-details-grid-right {
    padding-top: 2rem;
    grid-area: right;
  }

  /* .apply-wrapper {
    height: 60%;
  } */
  
  .apply-btn-wrapper {
    grid-area: apply;
  }

  .apply-page-long select {
    width: 100%;
  }
  
  .apply-page-long textarea {
    width: 100%;
  }

  .apply-page-long .question-title  {
    margin: 0.4rem 0 0.4rem 0; 
    font-size: 1rem
  }

  .apply-page-long select {
    width: 70%;
    margin-top: 0.2rem;
    margin-bottom: 0;
    background: #f7f7f7;
  }

  .apply-page-long input {
    width: 100%;
  }

  .select-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

  }

  .select-container span {
    width: 60%;
  }

  .internship-details-grid-right .question-title {
    margin: 0.8rem 0 0.8rem 0;
  }

  .apply-container {
    width: 100%;
    /* background-color:lightskyblue; */
  }
  .apply-progress {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .apply-progress-line {
    width: 5rem;
  }
  .apply-progress-circle {
    width: 2%;
  }
}

@media only screen and (max-width: 650px) {
  .apply-progress {
    align-items: center;
  }
  .apply-progress-line {
    width: 4rem;
  }
  .apply-progress-circle {
    width: 1rem;
    height: 1rem;
  }

  .apply-progress > span {
    width: 5.5rem;
  }

  .apply-step {
    font-size: 0.7rem;
    width: 5.5rem;
    text-align: center;
    margin: 6px 2px;
    margin-right: 2px;
    overflow: hidden;
    margin-left: -8px;
  }
  .apply-btn-wrapper {
    max-width: 70%;
    justify-content: space-around;
    margin-right: 0;
  }
  .exit {
    right: 1rem;
    font-size: 1.8rem;
    top: 0;
  }
  .apply-btn-wrapper .backbtn {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.9rem;
  }
  .apply-btn-wrapper .nextbtn {
    max-width: 1rem;
    height: 1rem;
    font-size: 0.9rem;
  }
  .select-accordion {
    width: 80%;
    margin-top: 1rem;
  }
  #myrange {
    min-height: 0.5rem;
    padding: 1rem;
  }
  .MuiSlider-root {
    max-width: 80%;
  }
  .apply-wrapper {
    height: 80%
  }
  .apply-page-long {
    min-height: 80%;
  }
  form {
    height: 100%;
  }
}



/* 
  Page Two Styling
*/

.requirements-details-grid {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-template-rows: 1fr 50px;
  grid-template-areas:
    "left right"
    "left right"
    "down apply";
    width: 100%;
}

.requirements-details-grid-left {
  grid-area: left;
}

.requirements-details-grid-down {
  grid-area: down;
}

form .skill-input {
  border-radius: 7px 7px 0 0;
}

.skills-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: -1px;
  background: white;
  border: 1px solid rgb(192, 191, 191);
  border-radius: 0 0 7px 7px;
  width: 80%;
  min-height: 11vh;
}

.skill {
  display: flex;
  align-items: center;
  border: 1px solid rgb(192, 191, 191);
  border-radius: 10px;
  padding: .2rem 0 0 .7rem;
  margin-left: 5px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  max-height: 3vh;
}

.skill p {
  font-size: 1.2rem;
  font-weight: 500;
}

.skill button {
  border: none;
  background: none;
}

.skill button:hover {
  cursor: pointer;
}

.skill button img {
  height: 2.2vh;
}

.legal-link {
  color: black;
}

@media (max-width: 770px) {
  .requirements-details-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 2.25fr 0px;
    grid-template-areas:
      "left"
      "down"
      "apply";
    width: 100%;
  }

  .requirements-details-grid-down {
    grid-area: down;
  }

  .requirements-details-grid-down .question-title {
    margin: 0.8rem 0 0.8rem 0;
  }
  
  form .skills-container {
    width: 100%;
    min-height: 5vh;
  }

  .skill {
    height: 2.5vh;
  }

  form {
    margin-top: -1rem;
  }

  .apply-btn-wrapper {
    align-self: flex-end;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 1rem;  
  }
}